
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ToSModal extends TypedVue {
  agreeToTerms = false;

  @Prop({ default: false })
  loading!: boolean;

  confirm(): void {
    this.$emit("confirm");
  }

  close(): void {
    this.$emit("close");
  }
}
