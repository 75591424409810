
import { TypedVue } from "@/store/types";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class BaseEventComponent<TOptionsType> extends TypedVue {
  @Prop() options!: TOptionsType;
  @Prop() stepId!: string;
  @Prop() value!: any;

  @Emit("complete")
  markComplete(returnData?: any): any {
    // TODO: Add return types
    return returnData;
  }
}
