
import { TypedVue } from "@/store/types";
import { Component, Emit, Prop } from "vue-property-decorator";
import { ComparisonAssessmentItem, ComparisonBenchmarkItem } from "./ComparisonRadarChartComponent.vue";

@Component({})
export default class ComparisonItemCompareModal extends TypedVue {
  @Prop({ default: false })
  public showNames!: boolean;

  @Prop()
  public candidates!: ComparisonAssessmentItem[];

  @Prop()
  public benchmarks!: ComparisonBenchmarkItem[];

  filterText = "";

  get filteredCandidates(): ComparisonAssessmentItem[] {
    return this.candidates.filter((candidate) => {
      return candidate.data.candidateName.toLowerCase().includes(this.filterText.toLowerCase());
    });
  }

  public unselectBenchmarks(): void {
    this.benchmarks.forEach((benchmark) => {
      benchmark.selected = false;
    });
  }

  public unselectCandidates(): void {
    this.candidates.forEach((candidate) => {
      candidate.selected = false;
    });
  }

  @Emit("Ok")
  public ok(): boolean {
    return true;
  }

  @Emit("Cancel")
  public cancel(): boolean {
    return true;
  }
}
