import { GetterTree } from "vuex";
import { StoreState, RootState } from "../types";

export enum Getters {
  GET_PROJECT_DIVERSITY_ENABLED = "GET_PROJECT_DIVERSITY_ENABLED",
}

export const getters: GetterTree<StoreState, RootState> = {
  [Getters.GET_PROJECT_DIVERSITY_ENABLED](state) {
    return state.assessmentState?.projectFeatures?.includes("diversity") || false;
  },
};
