import { Resource } from "@/store/admin/adminTypes";
import adminService from "@/services/adminService";

const resourceCache: Record<string, Record<string, Record<string, Resource>>> = {};

export async function getResource(module = "admin", lang = "en"): Promise<Resource> {
  if (resourceCache[module] && Object.keys(resourceCache[module]).length > 0) {
    const latestVersion = Object.keys(resourceCache[module]).sort().reverse()[0];
    if (resourceCache[module][latestVersion][lang]) {
      return resourceCache[module][latestVersion][lang];
    }
  }

  const res = await adminService.getModuleResource(module, lang);
  saveResource(module, lang, res.version, res);

  return res;
}

export async function getResourceByVersion(module = "admin", lang: string, version: string): Promise<Resource> {
  if (resourceCache[module] && resourceCache[module][version] && resourceCache[module][version][lang]) {
    return resourceCache[module][version][lang];
  }

  const res = await adminService.getModuleResource(module, lang, version);
  saveResource(module, lang, version, res);

  return res;
}

function saveResource(module = "admin", lang: string, version: string, res: Resource) {
  if (!resourceCache[module]) {
    resourceCache[module] = {};
  }

  if (!resourceCache[module][version]) {
    resourceCache[module][version] = {};
  }

  resourceCache[module][version][lang] = res;
}
