import axios from "axios";
import * as bootstrap from "bootstrap";

window.addEventListener("report-template-updated", (event: CustomEventInit) => {
  const reportContainer = event.detail.container as HTMLElement;

  if (!reportContainer) {
    console.error("Report container not found.");
    return;
  }

  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Accordions
  const accordions = reportContainer.getElementsByClassName("accordion") as HTMLCollectionOf<HTMLElement>;

  for (const accordion of accordions) {
    const targetId = accordion.getAttribute("accordion-target");
    const targetElement = targetId === null ? null : document.getElementById(targetId);

    if (targetElement) {
      if (targetElement.classList.contains("accordion-open")) {
        targetElement.style.maxHeight = targetElement.style.maxHeight ? "" : targetElement.scrollHeight + "px";
        accordion.classList.toggle("active");
      }

      accordion.addEventListener("click", () => {
        targetElement.classList.toggle("accordion-open");
        accordion.classList.toggle("active");
        targetElement.style.maxHeight = targetElement.style.maxHeight ? "" : targetElement.scrollHeight + "px";
      });
    }
  }

  // Component Rendering

  for (const targetContainer of document.querySelectorAll("[data-report-component]")) {
    const componentName = targetContainer.attributes.getNamedItem("data-report-component")?.value;
    const component = document.querySelector(`[data-report-component-source='${componentName}']`);

    if (component) {
      targetContainer.appendChild(component);
    }
  }

  // Feedback Component
  for (const feedbackComponent of document.querySelectorAll(".feedback-component")) {
    const popup = feedbackComponent.querySelector(".feedback-box-popup");
    const section = feedbackComponent.attributes.getNamedItem("data-analytics-section")?.value || "unknown";

    let submitted = false;
    const logevent = (action: string, name: string, value: string) => {
      (window as any)._paq?.push(["trackEvent", "Report", action, name, value]);
    };

    popup?.querySelector("button.btn-secondary")?.addEventListener("click", () => {
      popup.classList.add("hide");
    });

    popup?.querySelector("button.btn-primary")?.addEventListener("click", () => {
      const assessmentReg = /[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?/.exec(window.location.href);

      axios.post("/api/admin/feedback", { text: popup.querySelector("textarea")?.value ?? "", assessmentId: assessmentReg ? assessmentReg[0] : null });
      logevent("FeedbackComment", section, popup.querySelector("textarea")?.value ?? "");
      popup.classList.add("hide");
    });

    feedbackComponent.querySelector(".bi-hand-thumbs-up")?.addEventListener("click", () => {
      if (!submitted) {
        logevent("Feedback", section, "1");
        feedbackComponent.classList.add("submitted-up");
        submitted = true;
      }
    });

    feedbackComponent.querySelector(".bi-hand-thumbs-down")?.addEventListener("click", () => {
      if (!submitted) {
        logevent("Feedback", section, "-1");
        feedbackComponent.classList.add("submitted-down");
        popup?.classList.remove("hide");
        submitted = true;
      }
    });
  }
});
