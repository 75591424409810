import { MutationTree } from "vuex";
import { StoreState, AssessmentStatus, AssessmentState, AssessmentSchema, AssessmentStatusSummary } from "./types";
import Vue from "vue";

export enum Mutations {
  SET_ASSESSMENT_STATE = "SET_ASSESSMENT_STATE",
  SET_ASSESSNMENT_STATUS = "SET_ASSESSNMENT_STATUS",
  SET_ASSESSNMENT_STATUS_SUMMARY = "SET_ASSESSNMENT_STATUS_SUMMARY",
  SET_ASSESSMENT_TOKEN = "SET_ASSESSMENT_TOKEN",
  SET_ASSESSMENT_SCHEMA = "SET_ASSESSMENT_SCHEMA",
  ADD_ASSESMENT_ANSWER = "ADD_ASSESMENT_ANSWER",
  UPDATE_ASSESSMENT_ANSWERS = "UPDATE_ASSESSMENT_ANSWERS",
}

export const mutations: MutationTree<StoreState> = {
  [Mutations.UPDATE_ASSESSMENT_ANSWERS](state, answers: { [questionId: string]: any }): void {
    if (state.assessmentState && state.assessmentState.questionResponses) {
      state.assessmentState.questionResponses = answers;
    }
  },
  [Mutations.ADD_ASSESMENT_ANSWER](state, answer: { questionId: string; questionAnswer: any }): void {
    if (state.assessmentState && state.assessmentState.questionResponses) {
      state.assessmentState.questionResponses[answer.questionId] = answer.questionAnswer;
    }
  },
  [Mutations.SET_ASSESSMENT_STATE](state, assesmentState: AssessmentState): void {
    state.assessmentState = assesmentState;
  },
  [Mutations.SET_ASSESSNMENT_STATUS](state, status: AssessmentStatus): void {
    state.assessmentStatus = status;
  },
  [Mutations.SET_ASSESSMENT_SCHEMA](state, schema: AssessmentSchema): void {
    state.assessmentSchema = schema;
  },
  [Mutations.SET_ASSESSMENT_TOKEN](state, token: string): void {
    state.assessmentToken = token;

    if (sessionStorage) {
      sessionStorage.setItem("current-assessment-token", token);
    }
  },
  [Mutations.SET_ASSESSNMENT_STATUS_SUMMARY](state, statusSummary: AssessmentStatusSummary) {
    Vue.set(state, "assessmentStatusSummary", statusSummary);
  },
};
