
import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";
import { ProjectReportData, V2Project } from "@/store/admin/adminTypes";
import { Action } from "vuex-class";
import { Actions } from "@/store/admin/adminActions";

import SearchReport from "@/components/project-reports/SearchReport.vue";

const namespace = "admin";

@Component({
  components: {
    SearchReport,
  },
})
export default class ProjectReport extends TypedVue {
  public report: ProjectReportData | null = null;
  public project: V2Project | null = null;

  @Action(Actions.GET_PROJECT_REPORT_DATA, { namespace })
  getProjectReportData!: (reportId: string) => Promise<ProjectReportData>;

  @Action(Actions.GET_PROJECT_BY_ID, { namespace })
  getProjectById!: (projectId: string) => Promise<V2Project>;

  get loading() {
    return this.report === null || this.project === null;
  }

  get reportId() {
    return this.$route.params.id;
  }

  async mounted() {
    this.report = await this.getProjectReportData(this.reportId);
    this.project = await this.getProjectById(this.report.projectId);
  }
}
