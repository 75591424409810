
import { ProjectAggregates } from "@/lib/serviceTypes";
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment-mini";

@Component({})
export default class ProjectVisualSummary extends TypedVue {
  @Prop()
  launchDate!: string;

  @Prop()
  aggregates!: ProjectAggregates;

  @Prop()
  diversity!: boolean;

  // @Getter(Getters.GET_CURRENT_PROJECT_AGGREGATES, { namespace })
  // public aggregates!: ProjectAggregates;

  // get isLoading(): boolean {
  //   return this.$store.state.admin.projectAggregatesLoading;
  // }

  get loading(): boolean {
    return this.aggregates.assessmentsSent === undefined;
  }

  get completionRate(): number {
    if (this.aggregates.assessmentsSent === 0) {
      return 0;
    }
    return Math.floor(((this.aggregates.assessmentsComplete || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get diversityIndex(): number {
    if (this.aggregates.assessmentsSent === 0) {
      return 0;
    }
    return Math.floor(((this.aggregates.diversity.diverse || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get balancedLeaders(): number {
    if (this.aggregates.assessmentsSent === 0) {
      return 0;
    }
    return Math.floor(((this.aggregates.readiness.balanced || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get contextualLeaders(): number {
    if (this.aggregates.assessmentsSent === 0 || this.aggregates.readiness.contextual === 0) {
      return 0;
    }

    if (this.aggregates.readiness.niche === 0) {
      return 100 - this.balancedLeaders;
    }

    return Math.floor(((this.aggregates.readiness.contextual || 0) / (this.aggregates.assessmentsSent || 0)) * 100);
  }

  get nicheLeaders(): number {
    if (this.aggregates.assessmentsSent === 0 || this.aggregates.readiness.niche === 0) {
      return 0;
    }

    return 100 - (this.balancedLeaders + this.contextualLeaders);
  }

  toFriendlyDate(date: string): string {
    const mDate = moment.utc(date).local();
    return mDate.format("MMMM Do, YYYY");
  }
}
