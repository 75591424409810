
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";
import { Trait } from "@/lib/consts";

type GroupHeaders = "Must-haves" | "Nice-to-haves" | "Additionals";

@Component({})
export default class CompetenciesToggleSlideout extends TypedVue {
  @Prop() competencies!: { [Key in Trait as string]: 0 | 1 | 2 };
  public selectedAll: { [Key in GroupHeaders as string]: boolean } = {};
  public visibileCompetencies: string[] = [];
  public visible = false;

  public toggleSelectAll(group: GroupHeaders) {
    const groupList = this.groupedCompetencies.find((x) => x.header === group);

    if (groupList) {
      if (!this.selectedAll[group]) {
        this.visibileCompetencies = this.visibileCompetencies.filter((x) => !groupList.competencies.includes(x));
      } else {
        for (const comp of groupList.competencies) {
          if (!this.visibileCompetencies.includes(comp)) {
            this.visibileCompetencies.push(comp);
          }
        }
      }
    }
  }

  onSelectedCompetenciesChange() {
    this.selectedAll["Must-haves"] = this.mustHaveCompetencies.every((x) => this.visibileCompetencies.includes(x));
    this.selectedAll["Nice-to-haves"] = this.niceToHaveCompetencies.every((x) => this.visibileCompetencies.includes(x));
    this.selectedAll["Additionals"] = this.neutralCompetencies.every((x) => this.visibileCompetencies.includes(x));
  }

  saveOrdering() {
    this.$emit("save-ordering", this.visibileCompetencies);
    this.visible = false;
  }

  created() {
    this.visibileCompetencies = Object.keys(this.competencies);

    this.selectedAll = {
      "Must-haves": true,
      "Nice-to-haves": true,
      Additionals: true,
    };
  }

  get mustHaveCompetencies() {
    return Object.keys(this.competencies || {}).filter((x) => (this.competencies[x] ?? 0) === 2);
  }

  get niceToHaveCompetencies() {
    return Object.keys(this.competencies || {}).filter((x) => (this.competencies[x] ?? 0) === 1);
  }

  get neutralCompetencies() {
    return Object.keys(this.competencies || {}).filter((x) => (this.competencies[x] ?? 0) === 0);
  }

  get groupedCompetencies(): { header: GroupHeaders; competencies: string[] }[] {
    return [
      { header: "Must-haves", competencies: this.mustHaveCompetencies },
      { header: "Nice-to-haves", competencies: this.niceToHaveCompetencies },
      { header: "Additionals", competencies: this.neutralCompetencies },
    ];
  }
}
