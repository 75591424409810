import Vue from "vue";
import Vuex, { Module } from "vuex";
import { actions } from "./assessment/actions";
import { mutations } from "./mutations";
import { getters } from "./assessment/getters";
import { RootState, StoreState as AssessmentState, AssessmentStatus } from "./types";
import { StoreState as AdminState } from "./admin/adminTypes";
import { actions as adminActions } from "./admin/adminActions";
import { mutations as adminMutations } from "./admin/adminMutations";
import { getters as adminGetters } from "./admin/adminGetters";
import { actions as SystemActions } from "./internal/interalActions";
import { StoreState as SystemState } from "./internal/internalTypes";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export enum VuexModules {
  ADMIN = "admin", // TODO: Rename to SYSTEM
  ASSESSMENT = "assessment", // TODO: Rename to PLATFORM
  INTERNAL = "internal",
}

const assessmentModule: Module<AssessmentState, RootState> = {
  namespaced: true,
  state: {
    appVersion: 1.0,
    assessmentStatus: AssessmentStatus.UNKNOWN,
  },
  actions,
  mutations,
  getters,
};

const adminModule: Module<AdminState, RootState> = {
  namespaced: true,
  state: {
    projectThemes: {},
    projectBenchmarks: {},
    projectLoading: true,
    reports: {},
    literals: {},
    organizationProjects: [],
    projectAssessments: {},
    projectAggregates: {},
    user: undefined,
  },
  actions: adminActions,
  mutations: adminMutations,
  getters: adminGetters,
};

const internalModule: Module<SystemState, RootState> = {
  namespaced: true,
  state: {
    organizations: [],
  },
  actions: SystemActions,
};

export default new Vuex.Store<RootState>({
  plugins: [createPersistedState({})],
  modules: {
    [VuexModules.ASSESSMENT]: assessmentModule,
    [VuexModules.ADMIN]: adminModule,
    [VuexModules.INTERNAL]: internalModule,
  },
});
