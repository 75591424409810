import Vue from "vue";
import { MutationTree } from "vuex";
import { StoreState, ProjectTheme, Resource, Benchmark, V2Project, V2Assessment } from "./adminTypes";
import { CanadidateReportInfo } from "../types";
import { ProjectAggregates } from "@/lib/serviceTypes";

export enum Mutations {
  SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT",
  SET_PROJECTS = "SET_PROJECTS",
  SET_PROJECT_THEME = "SET_PROJECT_THEME",
  SET_PROJECT_BENCHMARKS = "SET_PROJECT_BENCHMARKS",
  SET_PROJECT_LOADING = "SET_PROJECT_LOADING",
  SET_PROJECT_LOADING_FALSE = "SET_PROJECT_LOADING_FALSE",
  SET_LITERALS = "SET_LITERALS",
  SET_REPORT = "SET_REPORT",
  SET_ORG_PROJECTS = "SET_ORG_PROJECTS",
  SET_PROJECT_ASSESSMENTS = "SET_PROJECT_ASSESSMENTS",
  SET_PROJECT_AGGREGATES = "SET_PROJECT_AGGREGATES",
}

export const mutations: MutationTree<StoreState> = {
  [Mutations.SET_CURRENT_PROJECT](state, project: V2Project): void {
    Vue.set(state, "currentProject", project);
    // state.currentProject = project;
  },
  [Mutations.SET_PROJECTS](state, projects: V2Project[]): void {
    for (const project of projects) {
      Vue.set(state.projectThemes, project.identifier, project.theme);
    }
  },
  [Mutations.SET_PROJECT_THEME](state, data: { projectId: string; theme: ProjectTheme }): void {
    if (state.currentProject) {
      state.currentProject.theme = data.theme;
    }
    Vue.set(state.projectThemes, data.projectId, data.theme);
  },
  [Mutations.SET_PROJECT_BENCHMARKS](state, data: { projectId: string; benchmarks: Benchmark[] }): void {
    // TODO: Maybe this should be benchmark objects and not id's
    Vue.set(state.projectBenchmarks, data.projectId, data.benchmarks);
  },
  [Mutations.SET_PROJECT_LOADING](state): void {
    state.projectLoading = true;
  },
  [Mutations.SET_PROJECT_LOADING_FALSE](state): void {
    state.projectLoading = false;
  },
  [Mutations.SET_REPORT](state, data: { assessmentId: string; report: CanadidateReportInfo }): void {
    state.reports[data.assessmentId] = data.report;
  },
  [Mutations.SET_ORG_PROJECTS](state, projects: V2Project[]): void {
    state.organizationProjects = projects;
  },
  [Mutations.SET_LITERALS](state, data: { module: string; version: string; literals: Resource }): void {
    if (!state.literals[data.module]) {
      state.literals[data.module] = {};
    }

    state.literals[data.module][data.version] = data.literals;
  },
  [Mutations.SET_PROJECT_ASSESSMENTS](state, data: { projectId: string | undefined; assessments: V2Assessment[] }): void {
    Vue.set(state.projectAssessments, data.projectId || "", data.assessments);
  },
  [Mutations.SET_PROJECT_AGGREGATES](state, data: { projectId: string | undefined; aggregates: ProjectAggregates }): void {
    Vue.set(state.projectAggregates, data.projectId || "", data.aggregates);
  },
};
