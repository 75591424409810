
import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";
import fileDownload from "js-file-download";
import adminService from "@/services/adminService";

@Component
export default class SystemAdminLanding extends TypedVue {
  hiringProject = "";
  orgChartTitle = "";
  csvProject = "";

  organizationChartFile: File | null = null;
  isUploadError = false;
  uploadError = "";
  csvLoading = false;
  csvError = false;

  get csvDisabled(): boolean {
    return !this.csvProject || this.csvLoading;
  }

  async uploadOrganizationChart(): Promise<void> {
    if (this.organizationChartFile) {
      const result = await adminService.uploadOrganizationChart(this.organizationChartFile, this.hiringProject, this.orgChartTitle);

      if (result.success) {
        alert(`${this.hiringProject} upload is successful!`);
        this.hiringProject = "";
        this.organizationChartFile = null;
      } else {
        this.isUploadError = true;
        this.uploadError = result.detail;
      }
    }
  }

  // TODO: Fix
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  organizationChartFileSelected(event: any) {
    this.isUploadError = false;
    this.uploadError = "";
    if (event.target.files) {
      this.organizationChartFile = event.target.files[0];
    } else {
      this.organizationChartFile = null;
    }
  }

  downloadAssesssmentDump(): void {
    if (this.csvLoading) {
      return;
    }
    this.csvLoading = true;
    this.csvError = false;
    adminService
      .getAssessmentsCsv(this.csvProject)
      .then((blob) => {
        const today = new Date();
        fileDownload(blob, `${this.csvProject}-scores-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}.csv`);
      })
      .finally(() => {
        this.csvProject = "";
        this.csvLoading = false;
      })
      .catch((err) => {
        console.error(err);
        this.csvError = true;
      });
  }

  logout(): void {
    this.$auth.logout({});
  }
}
