import { VueAuth } from "@/lib/auth/VueAuth";
import Vue from "vue";
import { Store } from "vuex";
import { Resource, StoreState as AdminState } from "./admin/adminTypes";
import { StoreState as SystemState } from "./internal/internalTypes";
import { ComparisonItem } from "@/lib/charts/HighChartThemes";
import { Trait } from "@/lib/consts";

export enum AssessmentStatus {
  ACTIVE,
  EXPIRED,
  INVALID,
  UNKNOWN,
}

export interface AssessmentStatusSummary {
  assessmentId: string;
  candidateName: string;
  candidateEmail: string;
  candidateLinkedIn?: string;
  hiringCompany: string;
  agreedToTermsAndConditions: boolean;
  isStarted: boolean;
  logo?: string;
}

export interface AssessmentState {
  assessmentId?: string;
  dateCreated?: Date;
  dateCompleted?: Date;
  lastAttemptDate?: Date;
  attempts?: number;
  candidateName?: string;
  candidateLinkedIn?: string;
  candidatePhone?: string;
  candidateEmail?: string;
  hiringCompany?: string;
  questionResponses?: { [questionId: string]: any };
  totalTestDuration?: number;
  agreedToTermsAndConditions?: boolean;
  isComplete?: boolean;
  language?: string;
  projectFeatures?: string[];
}

/*
export interface AssessmentSchema {
}*/

export interface StoreState {
  appVersion: number;
  assessmentToken?: string;
  assessmentStatus?: AssessmentStatus;
  assessmentStatusSummary?: AssessmentStatusSummary;
  assessmentState?: AssessmentState;
  assessmentSchema?: AssessmentSchema;
}

export interface CandidateInfo {
  CandidateName: string;
  CandidateLinkedIn: string;
  CandidatePhone: string;
  CondidateEmail: string;
}

export interface SchemaEventBase {
  events: AssessmentSchemaEvent<any>[]; // tslint:disable-line
  isCurrentComponent: boolean;
  steppingBack?: boolean;
}

export interface VideoEventOptions extends SchemaEventBase {
  url: string;
  type: string;
  subtitle: string | null;
  pauseImage: string | null;
}

export interface QuickQuestionOptions extends SchemaEventBase {
  question: string;
  choices: string[];
  videoUrl: string;
  videoType: string;
  backgroundUrl: string;
}

export interface VideoPopupOptions extends SchemaEventBase {
  time: number;
  text: string;
  name: string;
}

export interface AssessmentSchemaEvent<TEventOptionsType extends SchemaEventBase> {
  type: string;
  id: string;
  description: string;
  options: TEventOptionsType;
}

export interface TemplateSchema {
  id: string;
  name: string;
  template: string;
  isCustom: boolean;
  dateCreated: Date;
}

export interface AssessmentSchema {
  id: string;
  version: string;
  schema: { events: AssessmentSchemaEvent<any>[] }; // tslint:disable-line
}

export interface AssessmentPlatformConfig {
  API_SERVICE_ROOT: string;
  API_ADMIN_SERVICE_ROOT: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENTID: string;
  AUTH0_AUDIENCE: string;
  RAYGUN_KEY: string;
  RAYGUN_VERBOSE: boolean;
  MATOMO_HOST: string;
  MATOMO_SITEID: string;
  APPINSIGHTS_KEY: string;
  ENABLE_VIDEO_SKIP: boolean;
  APP_VERSION: string;
  PRODUCTION: boolean;
  JOTFORM_FORM_ID: string;
  JOTFORM_NO_DIVERSITY_FORM_ID: string;
  MIXPANEL_TOKEN: string;
  POST_ASSESSMENT_FORM_URL: string;
  USERGUIDING_REPORT_GUIDE_ID: string;
  CPA_BENCHMARK_ID: string;
}

export abstract class TypedVue extends Vue {
  public $store!: Store<RootState>;
  public $matomo!: any;
  public $auth!: VueAuth;
  public $config!: AssessmentPlatformConfig;
  public $resource!: Resource;
  public $mixpanel: any;
}

export interface ComparisonChartPreset {
  comparisonItems: ComparisonItem<unknown>[]; /// TODO: Do we store the data
  color?: string; // TODO: these may not have colors
  title: string;
  subtitle?: string;
  description?: string;
}
export interface ProjectReportData {
  assessmentIds: string[];
  comparisonPresets: ComparisonChartPreset[];
  focalTraits: Trait[];
}

export interface BaseReportInfo {
  id: string;
  type: string;
  version: string;
  projectId: string;
}

export interface CanadidateReportInfo extends BaseReportInfo {
  readiness: string; // TODO: Move this into data at some point
  data: { [key: string]: any };
}

export interface ProjectReportInfo extends BaseReportInfo {
  data: ProjectReportData;
}

export interface Project {
  projectId: string;
  name: string;
  projectType: string;
  organizationNodes: [];
  organizationSchema: [];
  theme: string;
}

export interface OrganizationChart {
  projectId: string;
  orgChart: any[];
  candidates: any[];
  parentOfHiringNode?: number;
  dummyNode?: any[];
}

export interface RootState {
  assessment: StoreState;
  admin: AdminState;
  system: SystemState;
}
