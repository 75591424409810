// eslint-disable @typescript-eslint/no-explicit-any

import { Options as HighChartOptions, PaneOptions, PointOptionsObject, SeriesAreasplineOptions, XAxisPlotBandsOptions, SeriesArearangeOptions } from "highcharts";
import { ColorMap, ComparisonItem, RadarChartProviderOptions, RadarChartTheme } from "./HighChartThemes";
import { TRAITS, TRAIT_GROUPINGS } from "@/lib/consts";

const TRAIT_BANDS = [
  /* TODO: Name to resource String */
  { color: "#222831", from: 0, to: 1.5, name: "Action-Oriented", traits: TRAIT_GROUPINGS["action-oriented"], angle: "4%" },
  { color: "#393E46", from: 1.5, to: 2.5, name: "Adaptable", traits: TRAIT_GROUPINGS["adaptable"], angle: "13.5%" },
  { color: "#00ADB5", from: 2.5, to: 4.5, name: "Rigorous", traits: TRAIT_GROUPINGS["dedicated"], angle: "24%" },
  { color: "#525FE1", from: 4.5, to: 6.5, name: "Influential", traits: TRAIT_GROUPINGS["influential"], angle: "36%", mirror: true },
  { color: "#F86F03", from: 6.5, to: 9.5, name: "Forward-Focused", traits: TRAIT_GROUPINGS["insightful"], angle: "53%", mirror: true },
  { color: "#FFA41B", from: 9.5, to: 12.5, name: "People-Oriented", traits: TRAIT_GROUPINGS["people-oriented"], angle: "73%" },
  { color: "#525FE1", from: 12.5, to: 14.5, name: "Principles-Driven", traits: TRAIT_GROUPINGS["principled"], angle: "90%" },
];

type ExtendedSeriesAreasplineOptions<T> = SeriesAreasplineOptions & { comparisonItem: T; comparisonObject: ComparisonItem<T> };
type ExtendedSeriesRangeOptions<T> = SeriesArearangeOptions & { comparisonItem: T; comparisonObject: ComparisonItem<T> };
type ExtendedComparisonItem<T> = ComparisonItem<T & { traitScores?: Record<string, number>; competencyScores?: Record<string, number>; traitRanges?: Record<string, number[]> }>;

export const TraitChartOptions = {};

export const TraitChartSeriesProvider =
  (options: RadarChartProviderOptions) =>
  <T>(comparisonItem: ExtendedComparisonItem<T>, getName: (item: T, showNames: boolean) => string): ExtendedSeriesAreasplineOptions<T> => ({
    type: "areaspline",
    comparisonObject: comparisonItem,
    comparisonItem: comparisonItem.data,
    id: comparisonItem.id,
    name: getName(comparisonItem.data, options.showNames),
    data: TRAITS.map((s) => ({ y: comparisonItem.data.traitScores ? comparisonItem.data.traitScores[s] : 0, comparisonItem, trait: s } as PointOptionsObject)),
    lineColor: comparisonItem.color || ColorMap[options.theme]["default"],
    fillColor: comparisonItem.data.traitRanges ? "transparent" : (comparisonItem.color || ColorMap[options.theme]["default"]) + "26",
    className: `series-${comparisonItem.id}`,
    marker: {
      symbol: "circle",
      enabled: true,
      states: {
        hover: {
          enabled: true,
        },
      },
      lineColor: comparisonItem.color || ColorMap[options.theme]["default"],
      fillColor: comparisonItem.color || ColorMap[options.theme]["default"],
    },
    point: {
      events: {
        mouseOver: (function (options) {
          return function (this: any) {
            //debugger;
            const group = TRAIT_BANDS.find((t) => t.traits.includes(this.trait));
            [...this.series.xAxis.labelGroup.div.getElementsByTagName("span")]
              .filter((e) => e.childNodes[0] && e.childNodes[0].attributes && !group?.traits.includes(e.childNodes[0].attributes.getNamedItem("data-trait").value))
              .forEach((e) => e.classList.add("fade-label"));
            [...this.series.chart.renderer.box.querySelectorAll("text[data-trait]")].filter((e) => e.attributes["data-trait"].value !== (group?.name ?? "")).forEach((e) => e.classList.add("fade-label"));

            if (options.onMouseOver) {
              options.onMouseOver.bind(this.series)(null);
            }
          };
        })(options),
        mouseOut: (function (options) {
          return function (this: any) {
            [...this.series.xAxis.labelGroup.div.getElementsByTagName("span")].forEach((e) => e.classList.remove("fade-label"));
            [...this.series.chart.renderer.box.querySelectorAll("text[data-trait]")].forEach((e) => e.classList.remove("fade-label"));

            if (options.onMouseOut) {
              options.onMouseOut.bind(this.series)(null);
            }
          };
        })(options),
      },
    },
  });

export const TraitChartBandSeriesProvider =
  (options: RadarChartProviderOptions) =>
  <T>(comparisonItem: ExtendedComparisonItem<T>, getName: (item: T, showNames: boolean) => string): ExtendedSeriesRangeOptions<T> => {
    const rangeProviderItem = {
      type: "areasplinerange",
      comparisonObject: comparisonItem,
      comparisonItem: comparisonItem.data,
      name: getName(comparisonItem.data, options.showNames),
      data: TRAITS.map((s) => (comparisonItem.data.traitRanges ? comparisonItem.data.traitRanges[s] : [0, 0])),
      animation: false,
      point: {},
      lineColor: "transparent",
      linkedTo: comparisonItem.id,
      opacity: 0.35,
      enableMouseTracking: false,
      marker: { enabled: false },
      fillColor: comparisonItem.color || ColorMap[options.theme]["default"],
    };

    return rangeProviderItem as ExtendedSeriesRangeOptions<T>;
  };

export const TraitChartOptionsProvider = (options: RadarChartProviderOptions): Partial<HighChartOptions> => ({
  chart: {
    ...RadarChartTheme.chart,
    events: {
      render: function (this: Highcharts.Chart & { pane?: PaneOptions[] }) {
        const svg = this.renderer;
        const tratTextNodes = this.container.querySelectorAll("text[data-trait]");

        tratTextNodes.forEach((e) => e.remove());
        svg.defs.element.querySelectorAll("[id='PlotBandsCurvedPath']").forEach((e) => e.remove());

        if (this.pane && this.pane[0] && this.pane[0].center) {
          const cx = this.plotLeft + this.plotWidth / 2;
          const cy = this.plotTop + this.plotHeight / 2;
          const r = parseInt(this.pane[0].center[2].toString(), 10) / 2 + 5;
          const bgBandWidth = 5;

          svg
            .path()
            .attr({
              id: "PlotBandsCurvedPath",
              d: `M ${cx} ${cy} m 0 ${-r} a ${r} ${r} 0 1 1 0 ${r * 2} a ${r} ${r} 1 1 1 0 ${-(r * 2)}`,
            })
            .add(svg.defs);

          svg
            .path()
            .attr({
              id: "PlotBandsCurvedPathBG",
              d: `M ${cx} ${cy} m 0 ${-r - bgBandWidth} a ${r} ${r} 0 1 1 0 ${(r + bgBandWidth) * 2} a ${r} ${r} 1 1 1 0 ${-((r + bgBandWidth) * 2)}`,
            })
            .add(svg.defs);

          if (this.axes.length > 0) {
            const bands = (this.axes[0] as any).plotLinesAndBands as XAxisPlotBandsOptions[];

            // svg
            //   .createElement("use")
            //   .attr({
            //     href: "#PlotBandsCurvedPathBG",
            //     stroke: "#F8F9FC",
            //     "stroke-width": 25,
            //     "clip-path": "url(#clip)",
            //   })
            //   .add();

            for (const bandInx in bands) {
              const trait = TRAIT_BANDS[bandInx];

              const label = svg
                .text()
                .attr({
                  zIndex: 3, // place on top of a pie
                  "text-anchor": "middle", // center text in a slice (middle angle)
                  "data-trait": trait.name,
                  class: `PlotBandsCurvedPath band-${bandInx}`,
                })
                .add();

              const offset = TRAIT_BANDS[bandInx].angle; // ((100 * (45 + Math.PI / 2)) / (Math.PI * 2)).toString() + "%";
              const textPath = document.createElementNS("http://www.w3.org/2000/svg", "textPath");
              const tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");

              if (trait.mirror) {
                tspan.setAttribute("rotate", "180");
                tspan.setAttribute("dy", "10");
                tspan.appendChild(document.createTextNode(trait.name.split("").reverse().join("")));
              } else {
                tspan.appendChild(document.createTextNode(trait.name));
              }

              textPath.setAttributeNS("http://www.w3.org/1999/xlink", "href", "#PlotBandsCurvedPath");
              textPath.setAttribute("startOffset", offset);
              textPath.setAttribute("transform", "rotate(90,100,100)");

              textPath.appendChild(tspan);
              label.element.appendChild(textPath);
            }
          }
        }
      },
    },
  },
  plotOptions: {
    series: {
      pointPlacement: "on", // align the data points with the tick marks
    },
  },
  tooltip: {
    enabled: false,
  },
  xAxis: {
    labels: {
      allowOverlap: true,
      style: {
        whiteSpace: "normal", // set to normal
      },
      useHTML: true,
      distance: 38,
    },
    tickInterval: 1,
    categories: TRAITS.map((trait) =>
      options.resourceLookup
        ? '<span class="x-axis-label" data-trait="' +
            trait +
            '" title="' +
            options.resourceLookup(`Report::NowWhat::Competency::Description::${trait}`) +
            '">' +
            options.resourceLookup(`Comparison::TraitChart::XAxisLabel::${trait}`)?.toString() +
            ' <i class="bi bi-info-circle-fill comparison-axis-hover"></i></span>' ?? trait
        : trait,
    ) /* TODO: Lookup text from literals */,
    plotBands: [
      ...TRAIT_BANDS.map(
        (t) =>
          ({
            from: t.from,
            to: t.to,
            color: "#FFFFFF",
            traits: t.traits,
            borderWidth: 10,
            label: {
              text: t.name,
              style: {
                display: "none",
                color: t.color,
                fontWeight: "bold",
                fontSize: "18px",
              },
            },
          } as XAxisPlotBandsOptions & { traits: string[] }),
      ),
    ],
  },
});
