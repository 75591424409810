
import { TypedVue, AssessmentState } from "@/store/types";
import { Actions } from "@/store/assessment/actions";
import { Component, Ref } from "vue-property-decorator";
import { BModal } from "bootstrap-vue";
import { Action } from "vuex-class";

const namespace = "assessment";

@Component({})
export default class Agreements extends TypedVue {
  private agreePrivacy = false;

  @Action(Actions.UPDATE_ASSESMENT_DATA, { namespace })
  updateAssessmentData!: (data: AssessmentState) => void;

  @Ref()
  privacyModal!: BModal;

  @Ref()
  touModal!: BModal;

  async next(): Promise<void> {
    this.updateAssessmentData({ agreedToTermsAndConditions: true });
    await this.$router.push({
      path: "Introduction",
      query: {
        lang: this.$route.query.lang,
        subtitles: this.$route.query.subtitles,
        videoId: "0",
        nextView: "Instructions",
      },
    });
  }

  showPrivacyPolicy(): void {
    this.privacyModal.show();
  }

  showTOU(): void {
    this.touModal.show();
  }
}
