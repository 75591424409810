import { Vue, Component } from "vue-property-decorator";
import createAuth0Client, { PopupLoginOptions, Auth0Client, RedirectLoginOptions, GetIdTokenClaimsOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions, LogoutOptions } from "@auth0/auth0-spa-js";
import { User } from "./User";
import { VueConstructor } from "vue";
export type Auth0Options = {
  domain: string;
  clientId: string;
  audience?: string;
  [key: string]: string | undefined;
};

export type RedirectCallback = (appState: any) => void;

@Component({})
export class VueAuth extends Vue {
  public loading = true;
  public user?: User;
  public auth0Client?: Auth0Client;
  public popupOpen = false;
  public error?: Error;

  async getUser(): Promise<User | undefined> {
    const user = await this.auth0Client?.getUser();
    const userObj = user ? new User(user) : undefined;

    return userObj;
  }

  get isAuthenticated() {
    return this.auth0Client?.isAuthenticated() || Promise.resolve(false);
  }

  /** Authenticates the user using a popup window */
  async loginWithPopup(o: PopupLoginOptions) {
    this.popupOpen = true;

    try {
      await this.auth0Client?.loginWithPopup(o);
    } catch (e) {
      console.error(e);
      this.error = e as Error;
    } finally {
      this.popupOpen = false;
    }

    this.user = await this.getUser();
  }

  /** Authenticates the user using the redirect method */
  async loginWithRedirect(o: RedirectLoginOptions) {
    if (o.organization) {
      localStorage.setItem("auth0_org", o.organization);
    }
    if (localStorage.getItem("auth0_org")) {
      o.organization = localStorage.getItem("auth0_org") || undefined;
    }

    const auth = this.auth0Client?.loginWithRedirect(o);
    this.user = await this.getUser();

    return auth;
  }

  checkSession(): Promise<void> {
    return this.auth0Client?.checkSession() ?? Promise.resolve();
  }

  /** Returns all the claims present in the ID token */
  getIdTokenClaims(o?: GetIdTokenClaimsOptions) {
    return this.auth0Client?.getIdTokenClaims(o);
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  async getTokenSilently(o: GetTokenSilentlyOptions) {
    const authOptions = { ...o };

    if (o.organization) {
      localStorage.setItem("auth0_org", o.organization);
    }

    if (localStorage.getItem("auth0_org")) {
      authOptions.organization = localStorage.getItem("auth0_org");
    }

    const token = await this.auth0Client?.getTokenSilently(authOptions);
    this.user = await this.getUser();

    return token || "";
  }

  /** Gets the access token using a popup window */
  async getTokenWithPopup(o: GetTokenWithPopupOptions) {
    const authOptions = { ...o };

    if (o.organization) {
      localStorage.setItem("auth0_org", o.organization);
    }

    if (localStorage.getItem("auth0_org")) {
      authOptions.organization = localStorage.getItem("auth0_org") as string;
    }

    const token = await this.auth0Client?.getTokenWithPopup(o);
    this.user = await this.getUser();

    return token;
  }

  /** Logs the user out and removes their session on the authorization server */
  logout(o: LogoutOptions) {
    localStorage.clear();
    return this.auth0Client?.logout(o);
  }

  /** Use this lifecycle method to instantiate the SDK client */
  async init(onRedirectCallback: RedirectCallback, redirectUri: string, auth0Options: Auth0Options) {
    // Create a new instance of the SDK client using members of the given options object
    this.auth0Client = await createAuth0Client({
      domain: auth0Options.domain,
      client_id: auth0Options.clientId,
      audience: auth0Options.audience,
      redirect_uri: redirectUri,
      useRefreshTokens: true,
      cacheLocation: "localstorage",
    });

    try {
      // If the user is returning to the app after authentication..
      if (window.location.search.includes("error=") || (window.location.search.includes("code=") && window.location.search.includes("state="))) {
        // handle the redirect and retrieve tokens
        const { appState } = (await this.auth0Client?.handleRedirectCallback()) ?? { appState: undefined };
        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        onRedirectCallback(appState);
      }
    } catch (e) {
      console.error(e);
      this.error = e as Error;
    } finally {
      // Initialize our internal authentication state when the page is reloaded
      this.user = await this.getUser();
      this.loading = false;
    }
  }
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState: any) => window.history.replaceState({}, document.title, window.location.pathname);

let instance: VueAuth;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = async ({ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, redirectUri = window.location.origin, ...options }) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new VueAuth();
  await instance.init(onRedirectCallback, redirectUri, options as Auth0Options);

  return instance;
};

type Auth0PluginOptions = {
  onRedirectCallback: RedirectCallback;
  redirectUri: string;
  domain: string;
  clientId: string;
  authObj?: VueAuth;
  audience?: string;
  [key: string]: string | RedirectCallback | undefined | VueAuth;
};

export const Auth0Plugin = {
  async install(Vue: VueConstructor, options: Auth0PluginOptions) {
    Vue.prototype.$auth = options.authObj || (await useAuth0(options));
  },
};
