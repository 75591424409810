import { getInstance } from "./VueAuth";
import { NavigationGuard, Route } from "vue-router";
import rg4js, { RaygunV2UserDetails } from "raygun4js";
import config from "@/lib/config";
import { RedirectLoginOptions } from "@auth0/auth0-spa-js";

export function inferOrganization(to?: Route) {
  const orgHostTokens = window.location.host
    .split(/[.:]/)
    .map((x) => x.toLowerCase().trim())
    .filter((x) => /[a-z]+/.test(x))
    .filter((x) => !["stage", "assessment", "system-3", "localhost", "dev", "com"].includes(x));

  return ((to && to.query["org"]) || (orgHostTokens.length > 0 ? orgHostTokens[0] : null) || localStorage.getItem("auth0_org")) as string;
}

export const authGuard: NavigationGuard = async (to, from, next) => {
  if (!to.meta?.requireAuth) {
    return next();
  }
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if ((await authService.isAuthenticated) && authService.user) {
      if (config.RAYGUN_KEY && authService.user) {
        rg4js("setUser", {
          identifier: authService.user.id,
          isAnonymous: false,
          email: authService.user.email,
          fullName: authService.user.givenName,
        } as RaygunV2UserDetails);
      }

      if (to.meta?.requiredRole) {
        if (authService.user.roles.indexOf(to.meta?.requiredRole) > -1) {
          return next();
        } else {
          return next("/accessdenied");
        }
      } else {
        return next();
      }
    }

    const org = inferOrganization(to);

    const auth_options = {
      appState: { targetUrl: !org && !to.meta?.allowNoOrg ? "/admin/selectOrganization" : to.fullPath },
    } as RedirectLoginOptions;

    if (org) {
      auth_options.organization = org;
    }

    // Otherwise, log in
    await authService.loginWithRedirect(auth_options);

    // Unwatch loading
    unwatch && unwatch();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  const unwatch = authService.$watch("loading", (loading: boolean) => {
    if (loading === false) {
      return fn();
    }
  });
};
