import { camelCase } from "lodash";

const NAMESPACE = "https://api.assesments.system-3.com";
const ROLE_NAMESPACE = `${NAMESPACE}/roles`;
const ORGS_NAMESPACE = `${NAMESPACE}/organizations`;
const TOS_NAMESPACE = `${NAMESPACE}/acceptedToS`;

export enum Roles {
  GLOBAL_ADMIN = "GlobalAdmin",
  ORGANIZATION_ADMIN = "OrganizationAdmin",
  ORGANIZATION_USER = "OrganizationUser",
  CANDIDATE = "Candidate",
}

export interface Auth0Organization {
  id: string;
  display_name: string;
  name: string;
}

export type UserKeys = string | boolean | null | undefined | Roles[] | Auth0Organization | Auth0Organization[] | (() => unknown) | ((role: Roles) => boolean);

export class User {
  sub!: string;
  names!: string;
  nickname!: string;
  picture!: string;
  updatedAt!: string;
  email!: string;
  emailVerified!: boolean;
  roles!: Roles[];

  provider?: string;
  id?: string;

  givenName?: string;
  familyName?: string;
  locale?: string;
  [key: string]: UserKeys; // TODO: There has to be a better way to do this

  constructor(auth0User: { [key: string]: UserKeys }) {
    if (!auth0User) return;
    for (const key in auth0User) {
      if (key.startsWith(NAMESPACE) || key.startsWith(ROLE_NAMESPACE)) {
        this[key] = auth0User[key] as any;
      } else {
        this[camelCase(key)] = auth0User[key] as any;
      }
    }

    this.sub = auth0User.sub as string;
    this.provider = this.sub.split("|")[0];
    this.id = this.sub.split("|")[1];
    this.roles = (auth0User[ROLE_NAMESPACE] as Roles[]) || [];
  }

  get isCandidate(): boolean {
    return this.roles.includes(Roles.CANDIDATE) && !this.org_id;
  }

  get isOrgAdmin(): boolean {
    return this.roles.includes(Roles.ORGANIZATION_ADMIN) || this.roles.includes(Roles.GLOBAL_ADMIN);
  }

  get currentOrganization(): Auth0Organization | null {
    return this.organizations.find((org) => org.id === this.orgId) || null;
  }

  get organizations(): Auth0Organization[] {
    return this[ORGS_NAMESPACE] as Auth0Organization[];
  }

  public hasAcceptedTos(): boolean {
    return this[TOS_NAMESPACE] === true;
  }

  public hasRole(role: Roles): boolean {
    return this.roles.includes(role);
  }
}
