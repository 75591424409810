
import { V2Project } from "@/store/admin/adminTypes";
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ProjectTitleComponent extends TypedVue {
  @Prop()
  currentProject!: V2Project | null;

  get currentProjectName(): string {
    if (this.currentProject) {
      if (this.currentProject.identifier === "") {
        return "Summary View of All Project Data";
      }
      return this.currentProject.name;
    }
    return "";
  }

  get currentProjectType(): string {
    if (this.currentProject) {
      if (this.currentProject.identifier === "") {
        return "";
      }
      return this.currentProject?.description || "";
    }
    return "";
  }

  get currentOrganization() {
    const currentOrgs = this.$auth.user?.organizations.map((x) => ({ value: x.id, text: x.display_name })) || [];
    const currentOrgId = this.$auth.user?.currentOrganization?.id || "";
    return currentOrgs.find((x) => x.value === currentOrgId) || "";
  }
}
