
import { Component, Vue, Ref } from "vue-property-decorator";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

@Component({})
export default class Instructions extends Vue {
  @Ref() videoTag!: HTMLVideoElement;

  private player: videojs.Player | null = null;
  private playerOptions: videojs.PlayerOptions = {
    controls: false,
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    },
    html5: {
      vhs: {
        experimentalBufferBasedABR: true,
      },
    },
  };
  private playerSrc: videojs.Tech.SourceObject = {
    src: this.$store.state.assessment.assessmentSchema.schema.meta.instruction_video.url,
    type: "application/x-mpegURL",
  };

  mounted(): void {
    this.player = videojs(this.videoTag, this.playerOptions);
    this.player.on("error", this.videoPlayerError.bind(this));
    this.player.src(this.playerSrc);
    this.player.ready(() => {
      this.player?.play();
    });
  }

  beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  videoPlayerError(): void {
    // Media error occurred. Attempt to fix the error after waiting 1000ms.
    const err = this.player?.error();

    // Clear the error from the player
    this.player?.error(null);

    if (err) {
      setTimeout(() => {
        if (this.player) {
          if (err.code === 2) {
            // Reload sources on code 2 "MEDIA_ERR_NETWORK"
            this.player.src("");
            this.player.src(this.playerSrc);
            this.player.load();
          }
          // Try playing again
          this.player.play();
        }
      }, 1000);
    }
  }

  async navigateToNextScreen() {
    await this.$router.push({
      path: "assessment",
      query: { lang: this.$route.query.lang, subtitles: this.$route.query.subtitles },
    });
  }
}
