// eslint-disable @typescript-eslint/no-explicit-any

import { Options as HighChartOptions, PointOptionsObject, SeriesAreasplineOptions } from "highcharts";
import { ColorMap, ComparisonItem, RadarChartProviderOptions, RadarChartTheme } from "./HighChartThemes";

const GROUPINGS = ["action-oriented", "adaptable", "dedicated", "influential", "insightful", "people-oriented", "principled"];

export const CompetencyGroupChartSeriesProvider =
  (options: RadarChartProviderOptions) =>
  <T>(
    comparisonItem: ComparisonItem<T & { traitScores?: Record<string, number>; competencyScores?: Record<string, number> }>,
    getName: (item: T, showNames: boolean) => string,
  ): SeriesAreasplineOptions & { comparisonItem: T } => ({
    type: "areaspline",
    comparisonItem: comparisonItem.data,
    name: getName(comparisonItem.data, options.showNames),
    data: GROUPINGS.map((g) => ({ y: comparisonItem.data.competencyScores ? comparisonItem.data.competencyScores[g] : 0, comparisonItem, competency: g } as PointOptionsObject)),
    lineColor: comparisonItem.color || ColorMap[options.theme]["default"],
    fillColor: (comparisonItem.color || ColorMap[options.theme]["default"]) + "26",
    events: {
      mouseOver: options.onMouseOver,
      mouseOut: options.onMouseOut,
    },
    marker: {
      symbol: "circle",
      states: {
        hover: {
          enabled: true,
        },
      },
      lineColor: comparisonItem.color || ColorMap[options.theme]["default"],
      fillColor: comparisonItem.color || ColorMap[options.theme]["default"],
    },
  });

export const CompetencyGroupChartOptionsProvider = (options: RadarChartProviderOptions): Partial<HighChartOptions> => ({
  chart: {
    ...RadarChartTheme.chart,
  },
  plotOptions: {
    series: {
      pointPlacement: "on", // align the data points with the tick marks
    },
  },
  tooltip: {
    enabled: false,
  },
  xAxis: {
    labels: {
      allowOverlap: true,
      style: {
        whiteSpace: "normal", // set to normal
      },
      useHTML: true,
      distance: 38,
    },
    tickInterval: 1,
    plotBands: [],
    categories: GROUPINGS.map((comp) => (options.resourceLookup ? options.resourceLookup(`Comparison::TraitChart::XAxisLabel::${comp}`)?.toString() ?? comp : comp)),
  },
});
