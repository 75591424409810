
import { Component, Prop } from "vue-property-decorator";
import { TypedVue } from "@/store/types";

import MarkdownIt from "markdown-it";

@Component
export default class CommentComponent extends TypedVue {
  @Prop({ default: "" })
  title!: string | null;
  @Prop({ default: "" })
  body!: string;

  md = new MarkdownIt({ html: true });

  get bodyHTML(): string {
    return this.md.render(this.body);
  }
}
