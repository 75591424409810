import { ActionTree } from "vuex";
import { StoreState, RootState, AssessmentStatus, AssessmentState } from "../types";
import { Mutations } from "../mutations";
import assessmentService from "@/services/assessmentService";

const CURRENT_ASSESSMENT_TOKEN_KEY = "current-assessment-token";

export enum Actions {
  SET_USER_INFO = "SET_USER_INFO",
  START_ASSESMENT_ATTEMPT = "START_ASSESMENT_ATTEMPT",
  GET_ASSESSMENT_SCHEMA = "GET_ASSESSMENT_SCHEMA",
  GET_ASSESSMENT_STATUS_SUMMARY = "GET_ASSESSMENT_STATUS_SUMMARY",
  GET_ASSESSMENT_TOKEN = "GET_ASSESSMENT_TOKEN",
  UPDATE_ASSESMENT_DATA = "UPDATE_ASSESMENT_DATA",
  UPDATE_ASSESSMENT_ANSWER = "UPDATE_ASSESSMENT_ANSWER",
  UPDATE_ASSESSMENT_ANSWERS = "UPDATE_ASSESSMENT_ANSWERS",
  GET_CANDIDATES_ASSESSMENT = "GET_CANDIDATES_ASSESSMENT",
}

export const actions: ActionTree<StoreState, RootState> = {
  async [Actions.UPDATE_ASSESSMENT_ANSWER]({ commit, state }, data: { questionId: string; questionAnswer: any }) {
    if (!state.assessmentToken) {
      throw new Error("assessment token not found.");
    }
    const existingReponses = state.assessmentState?.questionResponses ?? {};

    await assessmentService.updateAssessmentState(
      {
        questionResponses: {
          ...existingReponses,
          [data.questionId]: data.questionAnswer,
        },
      },
      state.assessmentToken,
    );

    commit(Mutations.ADD_ASSESMENT_ANSWER, {
      questionId: data.questionId,
      questionAnswer: data.questionAnswer,
    });
  },
  async [Actions.UPDATE_ASSESSMENT_ANSWERS]({ commit, state }, data: { questionId: string; questionAnswer: any }[]) {
    if (!state.assessmentToken) {
      throw new Error("assessment token not found.");
    }

    const questionResponses = data.reduce((agg, x) => {
      agg[x.questionId] = x.questionAnswer;
      return agg;
    }, {} as { [questionId: string]: any });

    await assessmentService.updateAssessmentState({ questionResponses }, state.assessmentToken);

    commit(Mutations.UPDATE_ASSESSMENT_ANSWERS, questionResponses);
  },
  async [Actions.UPDATE_ASSESMENT_DATA]({ commit, state }, assesmentData: AssessmentState) {
    if (!state.assessmentToken) {
      throw new Error("assessment token not found.");
    }

    const newState = await assessmentService.updateAssessmentState(assesmentData, state.assessmentToken);
    commit(Mutations.SET_ASSESSMENT_STATE, newState);
  },

  async [Actions.GET_ASSESSMENT_STATUS_SUMMARY]({ commit, state }) {
    const token = state.assessmentToken || (sessionStorage && sessionStorage.getItem(CURRENT_ASSESSMENT_TOKEN_KEY));

    if (token === null) {
      throw new Error("Assessment token not found.");
    }

    try {
      const summaryData = await assessmentService.getAssessmentStatusSummary(token);

      commit(Mutations.SET_ASSESSMENT_TOKEN, token);
      commit(Mutations.SET_ASSESSNMENT_STATUS_SUMMARY, summaryData);
      commit(Mutations.SET_ASSESSNMENT_STATUS, AssessmentStatus.ACTIVE);
    } catch (e: any) {
      if (e.request) {
        if (e.request.status === 403) {
          commit(Mutations.SET_ASSESSNMENT_STATUS, AssessmentStatus.EXPIRED);
        } else if (e.request.status === 401 || e.request.status === 404 || e.request.status === 410) {
          commit(Mutations.SET_ASSESSNMENT_STATUS, AssessmentStatus.INVALID);
        }
      } else {
        commit(Mutations.SET_ASSESSNMENT_STATUS, AssessmentStatus.UNKNOWN);
      }
    }
  },

  async [Actions.GET_ASSESSMENT_SCHEMA]({ commit, state }, lang?: string) {
    const token = state.assessmentToken || (sessionStorage && sessionStorage.getItem(CURRENT_ASSESSMENT_TOKEN_KEY));

    if (token === null) {
      throw new Error("Assessment token not found.");
    }

    const schema = await assessmentService.getAssessmentSchema(token, lang);
    commit(Mutations.SET_ASSESSMENT_SCHEMA, schema);

    return schema;
  },

  async [Actions.START_ASSESMENT_ATTEMPT]({ commit, state }, lang?: string) {
    const token = state.assessmentToken || (sessionStorage && sessionStorage.getItem(CURRENT_ASSESSMENT_TOKEN_KEY));

    if (token === null) {
      throw new Error("Assessment token not found.");
    }
    const req = await assessmentService.startAssessmentAttempt(token);

    commit(Mutations.SET_ASSESSNMENT_STATUS, req.status);

    if (req.status === AssessmentStatus.ACTIVE) {
      commit(Mutations.SET_ASSESSMENT_STATE, req.state);
      commit(Mutations.SET_ASSESSMENT_TOKEN, token);

      if (!state.assessmentSchema) {
        const schema = await assessmentService.getAssessmentSchema(token, lang);
        commit(Mutations.SET_ASSESSMENT_SCHEMA, schema);
      }
    }
  },
};
