
import { Component, Vue } from "vue-property-decorator";
import VideoComponent from "@/components/VideoComponent.vue";

@Component({
  components: {
    VideoComponent,
  },
})
export default class Introduction extends Vue {
  get videoUrl() {
    return this.$store.state.assessment.assessmentSchema.schema.meta.intro_video.url;
  }

  get subtitleUrl(): string {
    if (this.$route.query.lang === "fr") {
      return "https://assets.system-3.com/french-subtitles/intro-part-1.vtt";
    }
    return "https://assets.system-3.com/english-subtitles/intro-part-1.vtt";
  }

  async navigateToNextScreen() {
    await this.$router.push({
      path: (this.$route.query?.nextView as string) ?? "Agreements",
      query: { lang: this.$route.query.lang, subtitles: this.$route.query.subtitles },
    });
  }
}
