
import { Component } from "vue-property-decorator";
import { TypedVue } from "@/store/types";

@Component
export default class QuestionReorderingPopup extends TypedVue {
  cancel(): void {
    this.$emit("cancel");
  }
  nextQuestion(): void {
    this.$emit("continue");
  }
}
