
import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";
import ToSModal from "@/components/admin/ToSModal.vue";
import adminService from "@/services/adminService";
import TopHeader from "@/components/admin/TopHeader.vue";

@Component({
  components: {
    ToSModal,
    TopHeader,
  },
})
export default class DashboardView extends TypedVue {
  modalVisible = false;
  loading = false;

  get showHeader(): boolean {
    return this.$route.meta?.header?.enabled ?? true;
  }

  async mounted(): Promise<void> {
    // Check if user has agreed to ToS
    const u = await this.$auth.getUser();

    if (!u?.hasAcceptedTos() && !this.$route.meta?.ignoreTOS) {
      this.modalVisible = true;
    }
  }

  async onConfirm(): Promise<void> {
    this.loading = true;

    // Update user
    await adminService.updateCurrentUser({ acceptedTos: true });
    this.modalVisible = false;

    await this.$auth.getTokenSilently({ ignoreCache: true });
    this.loading = false;
  }
}
