import { render, staticRenderFns } from "./ComparisonRadarChartComponent.vue?vue&type=template&id=32d74a00&scoped=true"
import script from "./ComparisonRadarChartComponent.vue?vue&type=script&lang=ts"
export * from "./ComparisonRadarChartComponent.vue?vue&type=script&lang=ts"
import style0 from "./ComparisonRadarChartComponent.vue?vue&type=style&index=0&id=32d74a00&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d74a00",
  null
  
)

export default component.exports