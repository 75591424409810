
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class CandidateTag extends TypedVue {
  @Prop()
  tag!: string;

  get lowercaseTag(): string {
    return this.tag.toLowerCase().replace(/ /g, "-");
  }
}
