
import { Component } from "vue-property-decorator";
import { TypedVue } from "@/store/types";

@Component
export default class WelcomeSplash extends TypedVue {
  get logoUrl(): string | null {
    if (this.$store.state.assessment.assessmentStatusSummary?.logo) {
      return this.$store.state.assessment.assessmentStatusSummary.logo;
    }
    return null;
  }
}
