
import { TypedVue } from "@/store/types";
import { Organization, Project } from "@/store/admin/adminTypes";
import { Component, Watch } from "vue-property-decorator";
import adminService from "@/services/adminService";

@Component({})
export default class ProjectList extends TypedVue {
  projects: Project[] = [];
  organizations: Organization[] = [];
  currentPage = 1;
  perPage = 10;
  fields = [
    {
      key: "projectId",
      label: "Project ID",
      sortable: true,
      class: "table-id",
    },
    {
      key: "name",
      sortable: true,
    },
    {
      key: "projectType",
      label: "Type",
      sortable: true,
    },
    {
      key: "friendlyOrgName",
      label: "Organization",
      sortable: true,
    },
    {
      key: "dateCreated",
      label: "Date Created",
      sortable: true,
      formatter: function (value: string) {
        return new Date(value).toDateString();
      },
    },
    {
      key: "isDeleted",
      label: "Is Deleted",
      thClass: "table-deleted",
      sortable: true,
    },
    {
      key: "actions",
      label: "",
      class: "table-actions",
    },
  ];
  selectedProject = "";
  selectedProjectName = "";
  selectedProjectDescripton = "";
  selectedProjectLogoUrl = "";
  logo: File | null = null;
  dismissCountDown = 0;
  loading = false;

  get totalPages(): number {
    return Math.ceil(this.projects.length / this.perPage);
  }

  @Watch("logo")
  onLogoChanged(newLogo: File): void {
    this.selectedProjectLogoUrl = "";
    this.loading = true;
    adminService
      .uploadLogo(newLogo)
      .then((url) => {
        this.selectedProjectLogoUrl = url;
      })
      .finally(() => {
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        this.logo = null;
      });
  }

  async mounted(): Promise<void> {
    await this.getOrganizations();
    await this.getProjects();
  }

  async getProjects(): Promise<void> {
    await adminService.getAllProjects().then((res) => {
      this.projects = res.map((item) => {
        return {
          ...item,
          actions: null,
          id: res.indexOf(item),
          friendlyOrgName: this.organizations.find((org) => org.id === item.organization)?.displayName || item.organization,
        };
      });
    });
  }

  handleDeleteClick(project: Project): void {
    this.selectedProject = project.projectId;
    this.selectedProjectName = project.name;
    this.$bvModal.show("confirm-modal");
  }

  handleConfirmationClick(): void {
    adminService
      .deleteProject(this.selectedProject)
      .then(() => this.getProjects())
      .catch((err) => console.error(err));
    this.$bvModal.hide("confirm-modal");
  }

  pageBack(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  pageForward(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  copyProjectId(projectId: string): void {
    navigator.clipboard
      .writeText(projectId)
      .then(() => this.showAlert())
      .catch((err) => console.error(err));
  }

  async updateProject(): Promise<void> {
    this.loading = true;
    await adminService.updateProjectDetails(this.selectedProject, this.selectedProjectName, this.selectedProjectDescripton, this.selectedProjectLogoUrl).catch(() => (this.loading = false));
    await this.getProjects().catch(() => (this.loading = false));

    this.loading = false;
    this.closeModal();
  }

  async getOrganizations(): Promise<void> {
    this.organizations = await adminService.getOrganizations();
  }

  countDownChanged(dismissCountDown: number): void {
    this.dismissCountDown = dismissCountDown;
  }

  showAlert(): void {
    this.dismissCountDown = 4;
  }

  showModal(item: Project): void {
    this.selectedProject = item.projectId;
    this.selectedProjectName = item.name;
    this.selectedProjectDescripton = item.projectType;
    this.selectedProjectLogoUrl = item.logo ?? "";

    this.$bvModal.show("edit-modal");
  }

  closeModal(): void {
    this.selectedProject = "";
    this.selectedProjectName = "";
    this.selectedProjectDescripton = "";

    this.$bvModal.hide("edit-modal");
  }
}
