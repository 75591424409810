export const TRAITS = ["goal", "decisive", "adaptability", "con", "grit", "tact", "persuade", "strategic", "intellect", "vision", "empathy", "mentor", "relationship", "integrity", "auth"] as const;
export const SITUATIONS = ["company_rep", "internal_dynamics", "org_strategy", ",personal_rep", "process_mgmt", "social_impact", "stakeholder_partner_relations", "talent_mgmt", "urgent"];
export type Trait = (typeof TRAITS)[number];
export type Situation = (typeof SITUATIONS)[number];

export const TRAIT_GROUPINGS: Record<string, Trait[]> = {
  "action-oriented": ["goal", "decisive"],
  adaptable: ["adaptability"],
  influential: ["tact", "persuade"],
  insightful: ["strategic", "intellect", "vision"],
  "people-oriented": ["empathy", "mentor", "relationship"],
  principled: ["integrity", "auth"],
  dedicated: ["con", "grit"],
};

export const COMPETENCY_STRING_MAPPING: Record<string, string> = {
  "action-oriented": "action",
  adaptable: "adaptability",
  dedicated: "rigorous",
  influential: "influential",
  insightful: "forward",
  "people-oriented": "people",
  principled: "principle",
};

export const TRAIT_CLASS_RANGES = {
  goal: { low: 43.11, high: 61.07, lowerlow: 37.18919, mean: 49.13, standardDeviation: 12.04 },
  decisive: { low: 37.79, high: 60.52, lowerlow: 36.64182, mean: 48.58, standardDeviation: 21.58 },
  adaptability: { low: 48.45, high: 66.23, lowerlow: 42.35371, mean: 54.29, standardDeviation: 11.69 },
  con: { low: 45.37, high: 63.21, lowerlow: 39.33273, mean: 51.27, standardDeviation: 11.8 },
  grit: { low: 28.87, high: 49.15, lowerlow: 25.27212, mean: 37.21, standardDeviation: 16.69 },
  tact: { low: 46.13, high: 64.38, lowerlow: 40.49636, mean: 52.44, standardDeviation: 12.6 },
  persuade: { low: 48.67, high: 66.89, lowerlow: 43.01455, mean: 54.95, standardDeviation: 12.57 },
  strategic: { low: 40.85, high: 59.03, lowerlow: 35.15091, mean: 47.09, standardDeviation: 12.49 },
  intellect: { low: 38.97, high: 62.85, lowerlow: 38.96909, mean: 50.91, standardDeviation: 23.87 },
  vision: { low: 62.29, high: 82.85, lowerlow: 58.96909, mean: 70.91, standardDeviation: 17.25 },
  empathy: { low: 45.16, high: 63.13, lowerlow: 39.24881, mean: 51.19, standardDeviation: 12.05 },
  mentor: { low: 41.98, high: 60.49, lowerlow: 36.60545, mean: 48.55, standardDeviation: 13.14 },
  relationship: { low: 41.05, high: 58.92, lowerlow: 35.04182, mean: 46.98, standardDeviation: 11.86 },
  integrity: { low: 41.87, high: 63.46, lowerlow: 39.57515, mean: 51.52, standardDeviation: 19.28 },
  auth: { low: 46.52, high: 67.17, lowerlow: 43.28727, mean: 55.23, standardDeviation: 17.4 },
} as Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
