
import { TypedVue } from "@/store/types";
import { Component, Emit, Prop, Ref } from "vue-property-decorator";
import ComparisonItemCompareModal from "./ComparisonItemCompareModal.vue";
import { ComparisonAssessmentItem, ComparisonBenchmarkItem, ComparisonChartPreset, ComparisonItemAny } from "./ComparisonRadarChartComponent.vue";
import ComparisonColourPickerModal from "./ComparisonColourPickerModal.vue";
import MarkdownEditor from "@/components/input/MarkdownEditor.vue";
import { ColorMap } from "@/lib/charts/HighChartThemes";
import { Modal } from "bootstrap";

@Component({
  components: {
    ComparisonItemCompareModal,
    ComparisonColourPickerModal,
    MarkdownEditor,
  },
})
export default class AddComparisonItemComponent extends TypedVue {
  public showItemCompareModel = false;
  public showColourPickerModel = false;
  public savePresetName = "";
  public savePresetDescription = "";
  public isUpdatingPreset = false;

  public selectedColourItem: ComparisonItemAny | null = null;
  public selectedPreset: ComparisonChartPreset | null = null;

  @Ref("save-preset-modal")
  public savePresetModal!: Modal;

  @Prop({ default: "" })
  public colorUniqueKey!: string;

  @Prop({ default: false })
  public showNames!: boolean;

  @Prop()
  public candidates!: ComparisonAssessmentItem[];

  @Prop()
  public benchmarks!: ComparisonBenchmarkItem[];

  @Prop()
  public selectedItem!: ComparisonItemAny | null;

  @Prop({ default: "default" })
  public theme!: string;

  @Prop()
  public presets!: ComparisonChartPreset[];

  @Prop({ default: false })
  public presetEditMode!: boolean;

  @Emit("ToggleHideItem")
  public emitToggleHideItem(item: ComparisonAssessmentItem | ComparisonBenchmarkItem, show: boolean): { item: ComparisonAssessmentItem | ComparisonBenchmarkItem; show: boolean } {
    return { item, show };
  }

  get selectedBenchmarks(): ComparisonBenchmarkItem[] {
    return this.benchmarks.filter((b) => b.selected);
  }

  get selectedCandidates(): ComparisonAssessmentItem[] {
    return this.candidates.filter((c) => c.selected);
  }

  get hiddenCandidates(): ComparisonAssessmentItem[] {
    return this.selectedCandidates.filter((c) => c.hidden);
  }

  get visibleCandidates(): ComparisonAssessmentItem[] {
    return this.selectedCandidates.filter((c) => !c.hidden);
  }

  public clearSelectedItems(): void {
    this.selectedCandidates.forEach((c) => (c.selected = false));
    this.selectedBenchmarks.forEach((b) => (b.selected = false));
  }

  public clearPreset() {
    this.selectedPreset = null;
  }

  public loadPreset(preset: ComparisonChartPreset) {
    if (this.selectedPreset === preset) {
      this.$emit("Reset");
      return;
    }

    this.clearSelectedItems();
    this.selectedPreset = preset;

    preset.items.forEach((item) => {
      const foundItem = this.candidates.find((c) => c.id === item.id) || this.benchmarks.find((c) => c.id === item.id);
      if (foundItem) {
        foundItem.selected = true;
        foundItem.color = item.color;
      }
    });

    this.$emit("PresetSelected", preset);
  }

  public promptSavePreset() {
    this.savePresetModal.show();
  }

  public editPreset(preset: ComparisonChartPreset) {
    if (this.selectedPreset !== preset) {
      this.loadPreset(preset);
      this.selectedPreset = preset;
    }

    this.isUpdatingPreset = true;
    this.savePresetName = preset.name;
    this.savePresetDescription = preset.description;
    this.savePresetModal.show();
  }

  public removePreset(preset: ComparisonChartPreset) {
    this.$emit("RemovePreset", preset);
  }

  public savePreset(bvModalEvent: Modal.Event) {
    if (this.savePresetName.length === 0) {
      bvModalEvent.preventDefault();
      return;
    }

    if (this.isUpdatingPreset && this.selectedPreset) {
      this.selectedPreset.name = this.savePresetName;
      this.selectedPreset.description = this.savePresetDescription;
      this.$emit("UpdatePreset", this.selectedPreset);
    } else {
      const newPreset = {
        id: Math.random().toString(36),
        name: this.savePresetName,
        description: this.savePresetDescription,
        items: [...this.selectedCandidates, ...this.selectedBenchmarks],
      };

      this.$emit("SavePreset", {
        id: Math.random().toString(36),
        name: this.savePresetName,
        description: this.savePresetDescription,
        items: [...this.selectedCandidates, ...this.selectedBenchmarks],
      });

      this.selectedPreset = newPreset;
    }

    this.isUpdatingPreset = false;
    this.savePresetName = "";
    this.savePresetDescription = "";
  }

  public colourDialogReset(): void {
    if (this.selectedColourItem) {
      this.selectedColourItem.color = this.selectedColourItem.initialColor ?? ColorMap[this.theme]["default"];
    }

    this.showColourPickerModel = false;
  }

  public colourDialogComplete(color: string): void {
    if (this.selectedColourItem) {
      this.selectedColourItem.color = color;
      localStorage.setItem(`report-chartcolor-${this.colorUniqueKey}-${this.selectedColourItem.id}`, color);
    }

    this.showColourPickerModel = false;
  }

  public colourDialogClose(): void {
    this.showColourPickerModel = false;
  }

  public showColourPicker(item: ComparisonAssessmentItem | ComparisonBenchmarkItem): void {
    this.selectedColourItem = item;
    this.showColourPickerModel = true;
  }

  public addDialogClose(): void {
    this.showItemCompareModel = false;
  }

  public addItem(): void {
    if (this.selectedPreset) {
      this.selectedPreset = null;
      this.$emit("PresetDeSelected");
    }

    this.showItemCompareModel = true;
  }

  public removeItem(item: ComparisonAssessmentItem | ComparisonBenchmarkItem): void {
    if (this.selectedPreset) {
      this.selectedPreset = null;
      this.$emit("PresetDeSelected");
    }

    item.selected = false;
  }

  public toggleHideBenchmark(item: ComparisonBenchmarkItem): void {
    item.hidden = !item.hidden;
    this.emitToggleHideItem(item, !item.hidden);
  }

  public toggleHideCandidate(item: ComparisonAssessmentItem): void {
    if ((this.visibleCandidates.length === 1 && !item.hidden) || this.selectedCandidates.length === 1) {
      return; // Cannot remove all candidates (causes chart to warp)
    }

    item.hidden = !item.hidden;
    this.emitToggleHideItem(item, !item.hidden);
  }
}
