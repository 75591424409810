
import ProjectTitleComponent from "@/components/ProjectTitleComponent.vue";
import SummaryProject from "@/components/admin/SummaryProject.vue";
import SummaryAllProjects from "@/components/admin/SummaryAllProjects.vue";

import { Getter, State } from "vuex-class";
import { TypedVue } from "@/store/types";
import { V2Project, Assessment, ProjectTheme } from "@/store/admin/adminTypes";
import { Component } from "vue-property-decorator";
import { Getters } from "@/store/admin/adminGetters";

const namespace = "admin";
export interface SummaryAssessment extends Assessment {
  shortId: string;
  pinned: boolean;
}

@Component({
  components: {
    ProjectTitleComponent,
    SummaryProject,
    SummaryAllProjects,
  },
})
export default class Summary extends TypedVue {
  @Getter(Getters.GET_CURRENT_PROJECT, { namespace })
  public currentProject!: V2Project | null;

  @Getter(Getters.GET_CURRENT_PROJECT_THEME, { namespace })
  public projectTheme!: ProjectTheme;

  @State((state) => state.admin.projectLoading)
  isProjectLoading!: boolean;

  get isAllProjects(): boolean {
    if (this.currentProject) {
      return this.currentProject.identifier === "";
    }
    return false;
  }
}
