
import { TypedVue } from "@/store/types";
import { Organization, Project } from "@/store/admin/adminTypes";
import { Component, Prop, VModel } from "vue-property-decorator";
import Multiselect from "vue-multiselect";

interface ProjectOption {
  orgName: string;
  orgId: string;
  projects: Project[];
}

@Component({
  components: {
    Multiselect,
  },
})
export default class ProjectDropdown extends TypedVue {
  @Prop({ default: [] })
  readonly projects!: Project[];

  @Prop({ default: [] })
  readonly organizations!: Organization[];

  @Prop({ default: "" })
  readonly label!: string;

  @Prop({ default: true })
  readonly multiple!: boolean;

  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop({ default: null })
  readonly restrictProjectsToOrg!: string | null;

  @VModel()
  selectedProjects!: Project[];

  searchText = "";

  get projectChoice(): Project[] {
    return this.selectedProjects;
  }

  set projectChoice(projects: Project[]) {
    this.selectedProjects = projects;
  }

  get projectOptions(): ProjectOption[] {
    const orgProjects: Record<string, Project[]> = {};

    for (const project of this.projects) {
      if (this.restrictProjectsToOrg && project.organization !== this.restrictProjectsToOrg) {
        continue;
      }
      if (!orgProjects[project.organization]) {
        orgProjects[project.organization] = [project];
      } else {
        orgProjects[project.organization].push(project);
      }
    }

    const projectOptions: ProjectOption[] = [];
    for (const key of Object.keys(orgProjects)) {
      const org = this.organizations.find((o) => o.id === key);
      const text = `${org?.displayName ? org?.displayName : "N/A"} - ${org?.id ? org?.id : "N/A"}`;

      projectOptions.push({
        orgName: text,
        orgId: org?.id ? org.id : "",
        projects: orgProjects[key],
      });
    }

    // Search filter. If search text matches an org show all projects for that org otherwise show only projects that match the search text
    if (this.searchText !== "") {
      return projectOptions
        .filter((option) => {
          if (option.orgName.toLowerCase().includes(this.searchText.toLowerCase()) || option.orgId.toLowerCase().includes(this.searchText.toLowerCase())) {
            return true;
          }
          return option.projects.some((project) => project.name.toLowerCase().includes(this.searchText.toLowerCase()));
        })
        .map((option) => {
          return {
            ...option,
            projects: option.projects.filter((project) => {
              if (option.orgName.toLowerCase().includes(this.searchText.toLowerCase()) || option.orgId.toLowerCase().includes(this.searchText.toLowerCase())) {
                return true;
              }
              return project.name.toLowerCase().includes(this.searchText.toLowerCase());
            }),
          };
        });
    }

    return projectOptions;
  }

  searchChange(search: string) {
    this.searchText = search;
  }

  customLabel(project: Project): string {
    return project.name;
  }
}
