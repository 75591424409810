import { render, staticRenderFns } from "./NewProjectReport.vue?vue&type=template&id=de3b0490&scoped=true"
import script from "./NewProjectReport.vue?vue&type=script&lang=ts"
export * from "./NewProjectReport.vue?vue&type=script&lang=ts"
import style0 from "./NewProjectReport.vue?vue&type=style&index=0&id=de3b0490&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de3b0490",
  null
  
)

export default component.exports