import { TRAIT_CLASS_RANGES } from "./consts";
import { jStat } from "jstat";

function calculateCompetencyWeights(traitPriorities: { [trait: string]: 0 | 1 | 2 }, traitWeights: { [trait: string]: number }) {
  const competencyWeights: { [trait: string]: number } = {};
  const sumMustHaves = Object.keys(traitWeights)
    .filter((trait) => traitPriorities[trait] === 2)
    .reduce((sum, trait) => sum + traitWeights[trait], 0);
  const sumNiceToHaves = Object.keys(traitWeights)
    .filter((trait) => traitPriorities[trait] === 1)
    .reduce((sum, trait) => sum + traitWeights[trait], 0);

  for (const trait in traitPriorities) {
    const weight = traitWeights[trait];
    if (traitPriorities[trait] === 0) {
      competencyWeights[trait] = 0;
    } else if (traitPriorities[trait] === 1) {
      // Nice to have
      competencyWeights[trait] = weight / sumNiceToHaves + 0.5;
    } else if (traitPriorities[trait] === 2) {
      // Must have
      competencyWeights[trait] = weight / sumMustHaves + 1.5;
    }
  }

  return competencyWeights;
}

function calculateZScores(traitScores: { [trait: string]: number }, relativeTo: { [trait: string]: { mean: number; standardDeviation: number } } = TRAIT_CLASS_RANGES) {
  const zScores: { [trait: string]: number } = {};

  for (const trait in traitScores) {
    zScores[trait] = (traitScores[trait] - relativeTo[trait].mean) / relativeTo[trait].standardDeviation;
  }

  return zScores;
}

function calculateStandardDeviation(values: number[], usePopulation = false) {
  const mean = values.reduce((acc, val) => acc + val, 0) / values.length;
  return Math.sqrt(values.reduce((acc: number[], val) => acc.concat((val - mean) ** 2), []).reduce((acc, val) => acc + val, 0) / (values.length - (usePopulation ? 0 : 1)));
}

function calculateMeanAndDeviation(comparisonScores: { [trait: string]: number }[]) {
  const meanAndDeviation: { [trait: string]: { mean: number; standardDeviation: number } } = {};

  for (const trait in comparisonScores[0]) {
    const values = comparisonScores.map((score) => score[trait]);

    // To avoid having a standard deviation of 0, we add 1 to a random value if all values are the same
    if (values.every((v) => v === values[0])) {
      const randomIndex = Math.floor(Math.random() * values.length);
      values[randomIndex] += 1;
    }

    meanAndDeviation[trait] = {
      mean: values.reduce((acc, val) => acc + val, 0) / values.length,
      standardDeviation: calculateStandardDeviation(values, true),
    };
  }

  return meanAndDeviation;
}

function standardizeScores(traitScores: { [trait: string]: number }, competencyWeights: { [trait: string]: number }) {
  const zScores = calculateZScores(traitScores);
  const standardizedScores: { [trait: string]: number } = {};

  for (const trait in traitScores) {
    standardizedScores[trait] = zScores[trait] * competencyWeights[trait];
  }

  return standardizedScores;
}

function calculateMatchScore(standardizedScores: { [trait: string]: number }, competencyWeights: { [trait: string]: number }): number {
  let sumZScores = 0;
  for (const trait in standardizedScores) {
    if (competencyWeights[trait] > 0) {
      sumZScores += standardizedScores[trait];
    }
  }
  const zScore = sumZScores / Math.sqrt(Object.keys(competencyWeights).reduce((sum, trait) => sum + competencyWeights[trait] ** 2, 0));

  return jStat.normal.cdf(zScore, 0, 1) * 100;
}

function calculateSimilarityScore(candidateScores: { [trait: string]: number }, comparisonScores: { [trait: string]: number }) {
  const candidateZScores = calculateZScores(candidateScores);
  const comparisonZScores = calculateZScores(comparisonScores);

  const competencyDifferences: { [trait: string]: number } = {};
  const maxDifferences: { [trait: string]: number } = {};

  for (const trait in candidateZScores) {
    competencyDifferences[trait] = (candidateZScores[trait] - comparisonZScores[trait]) ** 2;

    if (Math.abs(comparisonScores[trait] - 20) > Math.abs(comparisonScores[trait] - 100)) {
      maxDifferences[trait] = 20;
    } else {
      maxDifferences[trait] = 100;
    }
  }

  const zScoresDifference = calculateZScores(maxDifferences);
  const differences: { [trait: string]: number } = {};
  for (const trait in comparisonZScores) {
    differences[trait] = (comparisonZScores[trait] - zScoresDifference[trait]) ** 2;
  }

  const numerator = Math.sqrt(Object.keys(competencyDifferences).reduce((sum, trait) => sum + competencyDifferences[trait], 0));
  const denominator = Math.sqrt(Object.keys(differences).reduce((sum, trait) => sum + differences[trait], 0));

  return (1 - numerator / denominator) * 100;
}

function calculateSimilarityScoreToGroup(candidateZScores: { [trait: string]: number }, relativeTo: { [trait: string]: { mean: number; standardDeviation: number } }) {
  const maxDifferences: { [trait: string]: number } = {};
  for (const trait in candidateZScores) {
    maxDifferences[trait] = Math.max(Math.abs(20 - relativeTo[trait].mean), Math.abs(100 - relativeTo[trait].mean)) / relativeTo[trait].standardDeviation;
  }

  const numerator = Object.keys(candidateZScores).reduce((sum, trait) => sum + Math.abs(candidateZScores[trait]), 0);
  const denominator = Object.keys(maxDifferences).reduce((sum, trait) => sum + maxDifferences[trait], 0);

  return (1 - numerator / denominator) * 100;
}

export function getMatchScore(candidateScores: { [trait: string]: number }, traitPriorities: { [trait: string]: 0 | 1 | 2 }, traitWeights: { [trait: string]: number }) {
  const competencyWeights = calculateCompetencyWeights(traitPriorities, traitWeights);
  const standardizedScores = standardizeScores(candidateScores, competencyWeights);
  const score = calculateMatchScore(standardizedScores, competencyWeights);

  return score;
}

export function getSimilarityScoreToSingle(candidateScores: { [trait: string]: number }, comparisonScores: { [trait: string]: number }) {
  return calculateSimilarityScore(candidateScores, comparisonScores);
}

export function getSimilarityScoreToGroup(candidateScores: { [trait: string]: number }, comparisonScores: { [trait: string]: number }[]) {
  const meanAndDeviation = calculateMeanAndDeviation(comparisonScores);
  const candidateZScores = calculateZScores(candidateScores, meanAndDeviation);

  return calculateSimilarityScoreToGroup(candidateZScores, meanAndDeviation);
}
