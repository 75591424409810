
import { TypedVue } from "@/store/types";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { Chrome as ColourPicker } from "vue-color";

@Component({
  components: {
    ColourPicker,
  },
})
export default class ComparisonColourPickereModal extends TypedVue {
  @Prop()
  colour!: string;

  @Watch("colour")
  onColourChange(newVal: string): void {
    this.selected = { hex: newVal };
  }

  selected = { hex: this.colour };

  @Emit("Ok")
  confirm(): string {
    return this.selected.hex;
  }

  @Emit("Reset")
  reset(): void {
    return;
  }

  @Emit("Close")
  close(): void {
    return;
  }
}
