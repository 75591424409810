
import { Auth0Organization } from "@/lib/auth/User";
import { TypedVue } from "@/store/types";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { Modal } from "bootstrap";

@Component({})
export default class OrganizationSelectModal extends TypedVue {
  @Ref("org-switch-modal")
  readonly modal!: Modal;

  @Prop()
  currentOrg!: Auth0Organization;

  @Prop()
  organizations!: Auth0Organization[];

  @Prop()
  show!: boolean;

  @Watch("show")
  onShowChange(show: boolean) {
    if (show) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  get sortedOrganizations() {
    return this.organizations.sort((a, b) => a.display_name.localeCompare(b.display_name));
  }

  selectedOrganization: Auth0Organization = this.currentOrg;
  orgLoading = false;

  async changeOrg(org: Auth0Organization) {
    if (org !== this.selectedOrganization) {
      this.selectedOrganization = org;
      this.orgLoading = true;

      try {
        await this.$auth.getTokenSilently({ organization: org.id, ignoreCache: true });
      } catch (e) {
        console.error(e);
      }

      localStorage.clear();
      this.orgLoading = false;
      await this.$router.go(0);
    }
  }

  close(): void {
    this.$emit("close");
  }
}
