
import BaseEventComponent from "@/components/BaseEventComponent.vue";
import { Component, Prop, Ref } from "vue-property-decorator";
import { VideoEventOptions, VideoPopupOptions, AssessmentSchemaEvent } from "@/store/types";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

interface PopupEvent extends VideoPopupOptions {
  displayed: boolean;
}

@Component
export default class VideoComponent extends BaseEventComponent<VideoEventOptions> {
  @Prop({ default: true }) autoPlay!: boolean;
  @Ref() videoTag!: HTMLVideoElement;

  public controlsVisible = false;
  public videoPlaying = false;
  public showStepForwardBtn = false;
  public enableSkipVideo = false;
  public videoPaused = false;
  public displaySubtitles = false;
  public controlsFade = 0;
  public buttonTimeout: number | null | undefined;

  private popups: PopupEvent[] = [];
  private player: videojs.Player | null = null;
  public playerOptions: videojs.PlayerOptions = {
    controls: this.options.steppingBack ? true : false,
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    },
    html5: {
      vhs: {
        experimentalBufferBasedABR: true,
      },
    },
  };

  created(): void {
    if (this.options.events) {
      const popupEvents = this.options.events.filter((event) => {
        return event.type === "Popup";
      }) as AssessmentSchemaEvent<VideoPopupOptions>[];
      this.popups = popupEvents.map((popup) => {
        return {
          ...popup.options,
          displayed: false,
        };
      });
    }
  }

  mounted(): void {
    this.player = videojs(this.videoTag, this.playerOptions);
    this.player.on("error", this.videoPlayerError.bind(this));
    this.player.src({
      src: this.options.url,
      type: this.options.type,
    });
    if (this.options.subtitle !== null) {
      this.player.addRemoteTextTrack(
        {
          kind: "subtitles",
          srclang: (this.$route.query.lang as string) ?? "en",
          label: (this.$route.query.lang as string) === "fr" ? "French" : "English",
          src: this.options.subtitle,
        },
        true,
      );
    }
    this.player.ready(() => {
      this.player?.play();
      if (this.$route.query.subtitles === "on" && !this.displaySubtitles) {
        this.showHideSubtitles();
      }
    });

    if (this.options.steppingBack) {
      this.showStepForwardBtn = true;
    }

    if (this.options.isCurrentComponent === false) {
      this.enableSkipVideo = true;
    }

    document.addEventListener("keydown", this.shortcutChecker.bind(this));
  }

  async pauseResumeVideo(): Promise<void> {
    if (this.videoTag.paused) {
      await this.videoTag.play();
      this.videoPaused = false;
    } else {
      this.videoTag.pause();
      this.videoPaused = true;
    }
  }

  showHideSubtitles(): void {
    this.displaySubtitles = !this.displaySubtitles;
    const subtitles = this.displaySubtitles ? "on" : "off";
    const tracks = this.player?.textTracks();
    if (tracks && tracks.length > 1) {
      if (this.displaySubtitles) {
        tracks[0].mode = "showing";
      } else {
        tracks[0].mode = "disabled";
      }
    }
    this.$router
      .replace({
        path: this.$route.path,
        query: { ...this.$route.query, subtitles },
      })
      .catch((err) => console.error(err));
  }

  videoPlayerError(): void {
    // Media error occurred. Attempt to fix the error after waiting 1000ms.
    const err = this.player?.error();

    // Clear the error from the player
    this.player?.error(null);

    if (err) {
      setTimeout(() => {
        if (this.player) {
          if (err.code === 2) {
            // Reload sources on code 2 "MEDIA_ERR_NETWORK"
            this.player.src("");
            this.player.src({
              src: this.options.url,
              type: this.options.type,
            });
            this.player.load();
          }
          // Try playing again
          this.player.play();
        }
      }, 1000);
    }
  }

  stepForward(): void {
    this.$emit("stepForward");
  }

  beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
    document.removeEventListener("keydown", this.shortcutChecker);
  }

  videoEnded(): void {
    this.markComplete();
  }

  async play(): Promise<void> {
    if (this.videoPlaying) {
      this.videoTag.pause();
    } else {
      await this.videoTag.play();
    }

    this.videoPlaying = !this.videoPlaying;
  }

  onTimeUpdate(): void {
    this.popups.forEach((popup) => {
      if ((this.videoTag?.currentTime ?? 0) > popup.time && !popup.displayed) {
        this.showToast(popup);
        popup.displayed = true;
      }
    });
  }

  fadeControls(): void {
    if (this.controlsFade === 1) {
      if (this.buttonTimeout != null) {
        clearTimeout(this.buttonTimeout);
      }

      this.buttonTimeout = setTimeout(() => {
        this.buttonTimeout = null;
        this.controlsFade = 0;
      }, 3000);
    }
    this.controlsFade = 1;
  }

  showToast(popup: PopupEvent): void {
    const h = this.$createElement;

    const vNodesMsg = h("div", { class: "text-left" }, [
      h(
        "strong",
        {
          class: "mb-0 toast-body-name",
          style: "font-size: 16px; color: #000000;",
        },
        popup.name,
      ),
      h("p", { class: "mb-0", style: "color: #000000;" }, popup.text),
    ]);

    const vNodesTitle = h("div", { class: ["d-flex", "flex-grow-1", "align-items-center", "mr-2"] }, [
      h("img", {
        class: "mb-1 mr-2 icon",
        attrs: { src: require("@/assets/messages_icon.png") },
      }),
      h("strong", { class: "mr-2 toast-title" }, "MESSAGES"),
      h("small", { class: "ml-auto text-italics toast-title-time" }, "now"),
    ]);

    this.$bvToast.toast([vNodesMsg], {
      title: [vNodesTitle],
      toaster: "video-toaster",
      toastClass: "distractor-toast",
      appendToast: true,
      autoHideDelay: 10500,
      solid: true,
    });
  }

  shortcutChecker(event: KeyboardEvent): void {
    if (this.$config.ENABLE_VIDEO_SKIP && event.key === "ArrowRight" && (event.ctrlKey || event.metaKey)) {
      this.markComplete();
    }
  }
}
