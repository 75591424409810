import HighCharts, { SeriesAreasplineOptions } from "highcharts";

export interface ComparisonItem<T> {
  data: T;
  id: string;
  locked?: boolean;
  selected?: boolean;
  hidden?: boolean;
  color?: string;
  initialColor?: string;
  highlighted?: boolean;
}

export interface RadarChartProviderOptions {
  theme: string;
  showNames: boolean;
  resourceLookup?: (key: string) => string;
  onMouseOver?: (event: PointerEvent | null) => void;
  onMouseOut?: (event: PointerEvent | null) => void;
}

export type SeriesFunction = <T>(
  comparisonItem: ComparisonItem<T & { traitScores?: Record<string, number>; competencyScores?: Record<string, number> }>,
  getName: (item: T, showNames: boolean) => string,
) => SeriesAreasplineOptions & { comparisonItem: T };

export const ColorMap = {
  default: {
    s3: "#8CC63F",
    niche: "#FFA73F",
    contextual: "#fad964",
    beta: "#8b93ab",
    default: "#8b93ab",
  },
  alternative: {
    s3: "#48BED9",
    niche: "#FFA73F",
    contextual: "#5D3FD3",
    beta: "#8b93ab",
    default: "#8b93ab",
  },
  gray: {
    s3: "#C5C9D5",
    niche: "#C5C9D5",
    contextual: "#C5C9D5",
    beta: "#8b93ab",
    default: "#8b93ab",
  },
  "default-ta": {
    s3: "#78b042",
    niche: "#e67300",
    contextual: "#d4af37",
    beta: "#8b93ab",
    default: "#8b93ab",
  },
  "cpa-default": {
    "Low Alignment": "#7DF3FF",
    "Moderate Alignment": "#14DB8F",
    "Moderately High Alignment": "#3D4344",
    "High Alignment": "#002453",
    default: "#7DF3FF",
  },
} as { [key: string]: { [key: string]: string } };

export const RadarChartTheme: Partial<HighCharts.Options> = {
  accessibility: {
    enabled: false,
  },
  chart: {
    polar: true,
    animation: false,
    marginTop: 20,
    height: "575px",
    style: {
      fontFamily: `"DIN 2014", Avenir, Helvetica, Arial, sans-serif`,
    },
  },

  pane: {
    startAngle: 0,
    endAngle: 360,
  },

  xAxis: {
    tickInterval: 10,
    tickPositions: [0, 1, 2, 3, 4, 5, 6, 7],
    min: 0,
    max: 7,
    labels: {
      style: {
        color: "#7e8496",
      },
      useHTML: true,
    },
  },

  yAxis: {
    min: 0,
    max: 110,
    tickInterval: 10,
    labels: {
      enabled: false,
    },
  },

  plotOptions: {
    series: {
      animation: false,
      pointStart: 0,
      pointInterval: 1,
      enableMouseTracking: true,
      stickyTracking: false,
      marker: {
        symbol: "circle",
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },

  tooltip: {
    enabled: true,
    useHTML: true,
    backgroundColor: "rgba(0,0,0,.8)",
    borderWidth: 0,
    borderRadius: 16,
    padding: 10,
    style: {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "normal",
    },
    shadow: false,
    followPointer: true,
  },

  title: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
};
