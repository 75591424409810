import Handlebars from "handlebars";
import { RegisterHandlebarHelpers } from "@/lib/templates/ReportHandlebarsHelpers";
import { RegisterHandlebarEditorHelpers } from "@/lib/templates/ReportHandlebarsEditorHelpers";

const TEMPLATE_ERROR_HTML = "<span style='color: red'>Error generating template (check logs).</span>";
const PARTIAL_TEMPLATES = require.context("@/components/reports/templates/partials", false, /\.html\.partial\.tmpl$/);

export function RenderHBTemplate(templateBody: string, data: any, resources: { [key: string]: string }, useEditorHelpers = false) {
  RegisterHandlebarHelpers(resources);

  if (useEditorHelpers) {
    RegisterHandlebarEditorHelpers(resources);
  }

  // Register Partials
  for (const partialFilename of PARTIAL_TEMPLATES.keys()) {
    Handlebars.registerPartial(partialFilename.split("/")[1].split(".")[0], PARTIAL_TEMPLATES(partialFilename).default);
  }

  const template = Handlebars.compile(templateBody);

  try {
    return template(data);
  } catch (err) {
    console.error(err);
    return TEMPLATE_ERROR_HTML;
  }
}
