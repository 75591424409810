
import NewProjectReport from "@/components/admin/NewProjectReport.vue";
import EditProjectSearchReport from "@/components/admin/EditProjectSearchReport.vue";
import { TypedVue } from "@/store/types";
import { Component, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { Actions } from "@/store/internal/interalActions";
import { Project, Organization, Assessment, Benchmark, ProjectReport, ProjectReportType } from "@/store/internal/internalTypes";
import { VuexModules } from "@/store";

@Component({
  components: {
    NewProjectReport,
    EditProjectSearchReport,
  },
})
export default class ManageProjectReports extends TypedVue {
  @Action(Actions.GET_PROJECTS_FOR_ORGNAIZATION, { namespace: VuexModules.INTERNAL })
  getProjectsForOrganization!: (org_id: string) => Promise<Project[]>;

  @Action(Actions.GET_ORGANIZATIONS, { namespace: VuexModules.INTERNAL })
  getOrgnaizations!: () => Promise<Organization[]>;

  @Action(Actions.GET_ASSESSSMENTS_FOR_PROJECT, { namespace: VuexModules.INTERNAL })
  getAssessmentsForProject!: (project_id: string) => Promise<Assessment[]>;

  @Action(Actions.GET_ASSESSMENTS_FOR_ORGANIZATION, { namespace: VuexModules.INTERNAL })
  getAssessmentsForOrg!: (organization: string) => Promise<Assessment[]>;

  @Action(Actions.GET_BENCHMARKS_FOR_PROJECT, { namespace: VuexModules.INTERNAL })
  getBenchmarksForProject!: (project_id: string) => Promise<Benchmark[]>;

  @Action(Actions.GET_REPORT, { namespace: VuexModules.INTERNAL })
  getReport!: (report_id: string) => Promise<ProjectReport>;

  @Action(Actions.DELETE_REPORT, { namespace: VuexModules.INTERNAL })
  deleteReport!: (report_id: string) => Promise<ProjectReport>;

  public organizations: Organization[] = [];
  public projects: Project[] | null = null;
  public projectAssessments: Assessment[] | null = null;
  public projectBencharks: Benchmark[] | null = null;
  public selectedOrganizationId: string | null = null;
  public selectedProjectId: string | null = null;
  public selectedProjectReportId: string | null = null;
  public selectedReportType: ProjectReportType | null = null;
  public loading = false;
  public editReport = false;
  public report: ProjectReport | null = null;
  public reportTypes: Array<{ name: string; value: ProjectReportType }> = [
    { name: "Team Effectiveness Project Report", value: "team-effectiveness" },
    { name: "Search Project Report", value: "search-report" },
  ];

  get selectedProject() {
    if (this.selectedProjectId) {
      return this.projects?.find((p) => p.identifier === this.selectedProjectId);
    }

    return null;
  }

  get reportAssessments() {
    if (this.report && this.projectAssessments) {
      return this.projectAssessments.filter((a) => this.report?.assessmentIds.includes(a.identifier));
    }
    return this.projectAssessments || [];
  }

  @Watch("selectedProjectId")
  async onSelectedProjectChange() {
    this.projectAssessments = null;
    this.projectBencharks = null;

    if (this.selectedProjectId) {
      const [assessments, benchmarks] = await Promise.all([this.getAssessmentsForProject(this.selectedProjectId), this.getBenchmarksForProject(this.selectedProjectId)]);
      this.projectAssessments = assessments;
      this.projectBencharks = benchmarks;
    }
  }

  @Watch("selectedOrganizationId")
  async onSelectedOrganizationChange() {
    this.projects = [];
    this.selectedProjectId = null;

    if (this.selectedOrganizationId) {
      this.projects = await this.getProjectsForOrganization(this.selectedOrganizationId);
    }
  }

  async created() {
    this.organizations = await this.getOrgnaizations();
  }

  async getAssessments() {
    if (this.selectedReportType === "search-report" && this.selectedOrganizationId) {
      this.projectAssessments = await this.getAssessmentsForOrg(this.selectedOrganizationId);
    }
  }

  async newProjectReport() {
    this.loading = true;
    await this.getAssessments();

    this.loading = false;
    this.editReport = true;
  }

  async editProjectReport() {
    if (this.selectedProjectReportId) {
      this.loading = true;
      this.report = await this.getReport(this.selectedProjectReportId);
      this.selectedReportType = this.report.type as ProjectReportType;
      await this.getAssessments();

      this.loading = false;
      this.editReport = true;
    }
  }

  async deleteProjectReport() {
    if (this.selectedProjectReportId) {
      if (confirm("Are you sure you want to delete this report?")) {
        this.loading = true;
        await this.deleteReport(this.selectedProjectReportId);
        this.projects = await this.getProjectsForOrganization(this.selectedOrganizationId as string);

        this.selectedProjectReportId = null;
        this.loading = false;
      }
    }
  }
}
