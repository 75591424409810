import Handlebars from "handlebars";

export function RegisterHandlebarEditorHelpers(resources: { [key: string]: string }) {
  function HandlebarsHelperResource(this: any, ...args: any[]): string {
    const resourceString = Array.from(args).slice(0, -1).join("");
    const resourceValue = resources[resourceString];

    if (resourceValue && resourceValue.indexOf("{{") > -1) {
      // This resource string contains Handlebars Code
      const template = Handlebars.compile(resourceValue);
      return `${template(this)}`;
    }

    return `{{#Resource '${resourceString}'}}{{/Resource}}`;
  }

  function HandlebarHelperReactive(this: any, key: string) {
    return `{{#Reactive '${key}'}}{{/Reactive}}`;
  }

  Handlebars.registerHelper("Resource", HandlebarsHelperResource);
  Handlebars.registerHelper("Reactive", HandlebarHelperReactive);
}
