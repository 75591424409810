
import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";
import adminService from "@/services/adminService";
import ProjectDropdown from "@/components/input/ProjectDropdown.vue";
import { Organization, Project } from "@/store/admin/adminTypes";

@Component({ components: { ProjectDropdown } })
export default class GenerateLink extends TypedVue {
  private generatedToken = "";
  private candidateEmail = "";
  private projectType = "";
  private selectedProject: Project | null = null;
  private selectedLang = "en";
  private organizations: Organization[] = [];
  private projects: Project[] = [];
  private orgsLoading = false;
  private projectsLoading = false;
  private alertVisible = 0;
  private sendEmail = false;
  private tokenLoading = false;
  private error: string | null = null;

  private emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  private langOptions = [
    { text: "English", value: "en" },
    { text: "French", value: "fr" },
  ];

  public candidateName = "";

  get fullTokenUrl(): string {
    let url = window.location.origin + "/?t=" + this.generatedToken;
    if (this.selectedLang !== "en") {
      url += "&lang=" + this.selectedLang;
    }

    return url;
  }

  get isLoading(): boolean {
    return this.orgsLoading || this.projectsLoading;
  }

  get isEmailValid(): boolean {
    return this.emailRegex.test(this.candidateEmail);
  }

  async mounted(): Promise<void> {
    this.orgsLoading = this.projectsLoading = true;
    await this.getOrganizations().finally(() => (this.orgsLoading = false));
    await this.getProjects().finally(() => (this.projectsLoading = false));
  }

  async getOrganizations(): Promise<void> {
    this.organizations = await adminService.getOrganizations();
  }

  async getProjects(): Promise<void> {
    await adminService.getProjects(null, null, false).then((res) => {
      this.projects = res;
    });
  }

  generateLink(): void {
    if (this.selectedProject) {
      this.tokenLoading = true;
      this.generatedToken = "";
      this.error = null;

      adminService
        .generateNewAssessmentToken(this.candidateName.trim(), this.candidateEmail.trim(), this.selectedProject.organization, this.selectedProject.projectId, this.projectType.trim(), this.sendEmail, this.selectedLang)
        .then((res) => {
          this.generatedToken = res;
        })
        .catch((err) => {
          this.error = err.message || "An error occurred while generating the token. Please try again later.";
        })
        .finally(() => {
          this.tokenLoading = false;
        });
    }
  }

  countDownChanged(dismissCountDown: number): void {
    this.alertVisible = dismissCountDown;
  }

  copyAssessmentUrl(): void {
    navigator.clipboard
      .writeText(this.fullTokenUrl)
      .then(() => (this.alertVisible = 4))
      .catch((err) => console.error(err));
  }
}
