import { Axios } from "axios";
import axios from "axios"; // tslint:disable-line
import { AssessmentState, AssessmentStatus, AssessmentSchema, AssessmentStatusSummary, TemplateSchema } from "@/store/types";
import config from "@/lib/config";
import { Template } from "@/store/admin/adminTypes";

class AssessmentService {
  private api: Axios;

  private static readonly DEFAULT_LANGUAGE = "en";

  constructor(baseAPIUrl = "/api") {
    this.api = axios.create({ baseURL: baseAPIUrl });
  }

  private genAuthHeader(token: string) {
    return { headers: { Authorization: `Bearer ${token}` } };
  }

  public async startAssessmentAttempt(token: string): Promise<{ status: AssessmentStatus; state?: AssessmentState }> {
    try {
      const req = await this.api.get<AssessmentState>(`/assessments/me`, this.genAuthHeader(token));

      switch (req.status) {
        case 200:
          return { state: req.data, status: AssessmentStatus.ACTIVE };
        case 403:
          return { status: AssessmentStatus.EXPIRED };
        default:
          return { status: AssessmentStatus.INVALID };
      }
    } catch (e) {
      return { status: AssessmentStatus.INVALID };
    }
  }

  public async getTemplate(templateName: string): Promise<TemplateSchema | null> {
    const req = await this.api.get<TemplateSchema>(`/templates/${encodeURIComponent(templateName)}`);

    return req.status === 200 ? req.data : null;
  }

  public async saveTemplate(name: string, originId: string, template: string, isCustom = true) {
    const req = await this.api.post<TemplateSchema>("/templates/", {
      name,
      originId,
      template,
      isCustom,
    });

    if (req.status !== 200) {
      throw new Error(`Unable to save template. ${req.data} (${req.status})`);
    }

    return req.data;
  }

  public async getTemplates(originId: string | null = null): Promise<Template[]> {
    const req = await this.api.get<{ templates: Template[] }>(`/templates/?origin_id=${originId}`);
    return req.data.templates;
  }

  public async updateAssessmentState(state: AssessmentState, token: string): Promise<AssessmentState> {
    const req = await this.api.patch<AssessmentState>("/assessments/me", state, this.genAuthHeader(token));
    return req.data;
  }

  public async getAssessmentSchema(token: string, lang?: string) {
    const req = await this.api.get<AssessmentSchema>(`/assessments/schema?lang=${lang || AssessmentService.DEFAULT_LANGUAGE}`, this.genAuthHeader(token));

    return req.data;
  }

  public async getAssessmentStatusSummary(token: string) {
    const req = await this.api.get<AssessmentStatusSummary>("/assessments/me/status", this.genAuthHeader(token));

    return req.data;
  }

  public async submitAssessmentFeedback(token: string, feedback: { [key: string]: number }) {
    const req = await this.api.post<null>("/feedback/", { surveyResponses: feedback }, this.genAuthHeader(token));

    return req.data;
  }
}

export default new AssessmentService(config.API_SERVICE_ROOT);
