
import { Trait, TRAIT_GROUPINGS } from "@/lib/consts";
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class CompetenciesListing extends TypedVue {
  @Prop() mustHaveCompetencies!: Trait[];
  @Prop() niceToHaveCompetencies!: Trait[];
  @Prop() competencies!: Trait[];
  @Prop() weights!: Record<Trait, number>;

  weightChoices = [
    { value: 5, text: "Most Important", class: "most-important" },
    { value: 4, text: "More Important", class: "more-important" },
    { value: 3, text: "Equally Important", class: "equally-important" },
    { value: 2, text: "Less Important", class: "less-important" },
    { value: 1, text: "Least Important", class: "least-important" },
  ];

  get groupedCompetencies() {
    return [
      { header: "Must-have Competencies", shortText: "Must-haves", competencies: [...this.mustHaveCompetencies].sort((a, b) => this.weights[b] - this.weights[a]) },
      { header: "Nice-to-have Competencies", shortText: "Nice-to-haves", competencies: [...this.niceToHaveCompetencies].sort((a, b) => this.weights[b] - this.weights[a]) },
      {
        header: "Additional Competencies",
        competencies: [...this.competencies].sort((a, b) => (this.$t(`Comparison::TraitChart::XAxisLabel::${a}`) as string).localeCompare(this.$t(`Comparison::TraitChart::XAxisLabel::${b}`) as string)),
      },
    ];
  }

  public getWeightInfo(level: number) {
    return this.weightChoices.find((choice) => choice.value === Number(level));
  }

  public categoryFor(trait: Trait) {
    return Object.keys(TRAIT_GROUPINGS).find((group) => TRAIT_GROUPINGS[group].includes(trait));
  }
}
