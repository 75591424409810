import { S3PlatformApi } from "./serviceTypes";
import { getInstance } from "@/lib/auth/VueAuth";
import router from "@/router";

const backendAPI = new S3PlatformApi();

backendAPI.instance.interceptors.request.use(async (config) => {
  const auth = getInstance();
  if (auth) {
    const token = await auth.getTokenSilently({});
    if (config && config.headers) {
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
  }

  return config;
});

backendAPI.instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      router.push("/accessdenied").catch((err) => console.error(err));
    }
    return Promise.reject(error);
  },
);

export const internalAPI = backendAPI.internal;
export const systemAPI = backendAPI.system;
// TODO: Setup the platform API
// export const platformAPI = api.platform;
