
import { Component } from "vue-property-decorator";
import { TypedVue } from "@/store/types";

@Component({})
export default class AccessDenied extends TypedVue {
  goBack(): void {
    this.$auth.logout({ returnTo: "/" });
  }
}
