
import { Benchmark, ProjectTheme, themeNames, V2Project } from "@/store/admin/adminTypes";
import { TypedVue } from "@/store/types";
import { Component, Watch, Ref, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Actions } from "@/store/admin/adminActions";
import adminService from "@/services/adminService";
import { VuexModules } from "@/store";
import { Getters } from "@/store/admin/adminGetters";
import { Modal } from "bootstrap";
import { Roles } from "@/lib/auth/User";

@Component({})
export default class EditProjectModal extends TypedVue {
  @Ref("project-switch-modal")
  readonly modal!: Modal;

  @Action(Actions.CHANGE_CURRENT_THEME, { namespace: VuexModules.ADMIN })
  selectTheme!: (theme: ProjectTheme) => Promise<void>;

  @Action(Actions.CHANGE_PROJECT_BENCHMARKS, { namespace: VuexModules.ADMIN })
  selectBenchmarks!: (benchmarks: Benchmark[]) => Promise<void>;

  @Getter(Getters.GET_CURRENT_PROJECT_THEME, { namespace: VuexModules.ADMIN })
  public currentTheme!: ProjectTheme;

  @Getter(Getters.GET_CURRENT_PROJECT, { namespace: VuexModules.ADMIN })
  public currentProject!: V2Project;

  @Prop()
  show!: boolean;

  @Watch("show")
  onShowChange(show: boolean) {
    if (show) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  @Watch("selectedTheme")
  onSelectedThemeChange(val: ProjectTheme) {
    this.selectTheme(val);
  }

  public themes = Object.values(ProjectTheme);
  public benchmarks: Benchmark[] = [];
  public selectedTheme: ProjectTheme = this.currentTheme || ProjectTheme.DEFAULT;

  get selectedBenchmarks(): string[] {
    return this.currentProject?.benchmarks?.map((x) => x.identifier) || [];
  }

  set selectedBenchmarks(benchmarks: string[]) {
    this.selectBenchmarks(this.benchmarks.filter((b) => benchmarks.includes(b.benchmarkId))).catch((err) => console.error(err));
  }

  get benchmarkOptions(): Array<{ text: string; value: string }> {
    return this.benchmarks.map((benchmark) => {
      return {
        text: benchmark.name,
        value: benchmark.benchmarkId,
      };
    });
  }

  get showBenchmarksOptions() {
    return (this.$auth.user?.hasRole(Roles.GLOBAL_ADMIN) || false) && this.currentProject.identifier !== "";
  }

  async mounted(): Promise<void> {
    this.benchmarks = await adminService.getBenchmarks();
    this.selectedTheme = this.currentTheme;
  }

  getThemeName(theme: ProjectTheme): string {
    return themeNames[theme];
  }

  close(): void {
    this.$emit("close");
  }
}
