
import { Component } from "vue-property-decorator";
import SurveyComponent from "@/components/SurveyComponent.vue";
import { TypedVue } from "@/store/types";
import { Getter } from "vuex-class";
import { Getters } from "@/store/assessment/getters";

const namespace = "assessment";

@Component({
  components: {
    SurveyComponent,
  },
})
export default class AssessmentComplete extends TypedVue {
  @Getter(Getters.GET_PROJECT_DIVERSITY_ENABLED, { namespace })
  public diversityEnabled!: boolean;

  proceed(): void {
    const assessmentId = this.$store.state.assessment.assessmentState?.assessmentId;
    window.location.href = `https://form.jotform.com/${this.diversityEnabled ? this.$config.JOTFORM_FORM_ID : this.$config.JOTFORM_NO_DIVERSITY_FORM_ID}?assessmentId=${assessmentId}`;
  }
}
