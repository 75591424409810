
import { Auth0Organization } from "@/lib/auth/User";
import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";

@Component({})
export default class SelectOrganization extends TypedVue {
  public organizations: Auth0Organization[] = [];
  public selectedOrganziation: Auth0Organization | null = null;

  async mounted() {
    this.organizations = this.$auth.user?.organizations || [];

    if (this.organizations.length === 1) {
      await this.pickOrg(this.organizations[0]);
    }
  }

  async pickOrg(org: Auth0Organization) {
    this.selectedOrganziation = org;

    try {
      await this.$auth.getTokenSilently({ organization: org.id, ignoreCache: true });
    } catch (e) {
      await this.$auth.loginWithRedirect({ organization: org.id });
    }

    await this.$router.replace("/admin/summary");
  }
}
