import { Resource } from "./admin/adminTypes";

export function emptyResource(): Resource {
  return {
    dateCreated: "",
    language: "",
    module: "",
    version: "",
    dictionary: {},
  };
}
