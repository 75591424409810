
import "@kangc/v-md-editor/lib/style/base-editor.css";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";

import { Component, Prop, VModel } from "vue-property-decorator";
import { TypedVue } from "@/store/types";

import VueMarkdownEditor from "@kangc/v-md-editor";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import enUS from "@kangc/v-md-editor/lib/lang/en-US";

VueMarkdownEditor.use(vuepressTheme);
VueMarkdownEditor.lang.use("en-US", enUS);

@Component({
  components: {
    VueMarkdownEditor,
  },
})
export default class MarkdownEditor extends TypedVue {
  @Prop({ default: "400px" })
  height!: string;
  @VModel({ type: String }) markdownText!: string;
}
