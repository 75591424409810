
import { Component, Prop, VModel } from "vue-property-decorator";
import { TypedVue } from "@/store/types";
import { Competency } from "@/store/admin/adminTypes";

@Component({})
export default class EditReportCompetencyComponent extends TypedVue {
  @Prop({ default: "" })
  title!: string;

  @VModel()
  competency!: Competency;

  // get traitInsights(): string {
  //   return this.competency.traitInsights.join("\n");
  // }

  // set traitInsights(text: string) {
  //   this.competency.traitInsights = text.split("\n");
  // }

  // get optimalInsights(): string {
  //   return this.competency.optimalContextInsights.join("\n");
  // }

  // set optimalInsights(text: string) {
  //   this.competency.optimalContextInsights = text.split("\n");
  // }

  // get fragileInsights(): string {
  //   return this.competency.fragileContextInsights.join("\n");
  // }

  // set fragileInsights(text: string) {
  //   this.competency.fragileContextInsights = text.split("\n");
  // }

  // get hitTraits(): string {
  //   return this.competency.hitTraitBehaviours.join("\n");
  // }

  // set hitTraits(text: string) {
  //   this.competency.hitTraitBehaviours = text.split("\n");
  // }

  // get missTraits(): string {
  //   return this.competency.missTraitBehaviours.join("\n");
  // }

  // set missTraits(text: string) {
  //   this.competency.missTraitBehaviours = text.split("\n");
  // }
}
