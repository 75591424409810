
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class StateBadgeComponent extends TypedVue {
  @Prop()
  state!: string;

  get displayName(): string {
    switch (this.state) {
      case "incomplete":
        return "Incomplete";
      case "in-progress":
        return "In Progress";
      case "complete":
      case "scoring_complete":
        return "Pending";
      case "report-generated":
        return "Complete";
    }
    return this.state;
  }
}
