
import { Component } from "vue-property-decorator";
import { TypedVue } from "@/store/types";
import assessmentService from "@/services/assessmentService";

@Component
export default class SurveyComponent extends TypedVue {
  submitted = false;
  ratingQuestions = ["This simulation was difficult.", "This simulation was hard to cheat.", "The details in this simulation seemed realistic.", "This simulation was engaging."];
  ratingText = ["Strongly disagree", "Somewhat disagree", "Neither agree nor disagree", "Somewhat agree", "Strongly agree"];
  ratings = Array.apply(null, Array(this.ratingQuestions.length)).map(() => 0);

  async submitFeedback(): Promise<void> {
    this.submitted = true;
    const feedback: { [key: string]: number } = {};
    this.ratings.forEach((rating, index) => {
      feedback[`q${index + 1}`] = rating;
    });

    await assessmentService.submitAssessmentFeedback(this.$store.state.assessment.assessmentToken ? this.$store.state.assessment.assessmentToken : "", feedback).finally(() => {
      const assessmentId = this.$store.state.assessment.assessmentState?.assessmentId;
      window.location.href = `${this.$config.POST_ASSESSMENT_FORM_URL}?assessmentId=${assessmentId}`;
    });
  }
}
